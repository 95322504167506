interface CounterProps {
  label: string,
  value: number,
  minValue?: number,
  maxValue?: number,
  counterHandler: (i:number) => void
}

export const Counter = (props: CounterProps) => {
  const { value, label, counterHandler, minValue, maxValue } = props;

  let checkMargins = (action: number) => {
    if(action === -1 && minValue) {
      if(value > minValue)
        return counterHandler(-1);
      else
        return;
    } else if (action === 1 && maxValue) {
      if(value < maxValue)
        return counterHandler(1);
      else 
        return;
    }
    // default
    return counterHandler(action);
  }
  
  return (
    <div className="input-wrapper">
      <div className="label input__label">{label}</div>
      <div className="quantity">
        <div className={"quantity__minus" + (value === minValue ? " disabled": "")} onClick={() => {checkMargins(-1)}}></div>
        <div className="quantity__value"><h5>{value}</h5></div>
        <div className={"quantity__plus" + (value === maxValue ? " disabled": "")} onClick={() => {checkMargins(1)}}></div>
      </div>
    </div>
  )
}