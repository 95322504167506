import axios from 'axios';
import config from '../app/config';

let instance = axios.create({
  baseURL: config.api
});

// Request interceptor for API calls
instance.interceptors.request.use(
  async (config) => {
    config.headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`
    };
    return config;
  }
);

export default instance;