import { useTranslation } from "react-i18next";

interface PromptScheme {
  onAction: (action: boolean) => void,
  opened: boolean,
  age: number
}

const AgePrompt = (props: PromptScheme) => {
  // translate
  const {t} = useTranslation(['general', 'buttons']);
  return (
    <div className={"prompt-wrapper container" + (props.opened ? " opened" : "")}>
      <h2>{t("confirm_age", {ns: 'general', age: props.age})}</h2>
      <div className="prompt-buttons grid">
        <div className="button--invert col-6" onClick={() => props.onAction(false)}>{t("back", {ns: 'buttons'})}</div>
        <div className="button col-6" onClick={() => props.onAction(true)}>{t("confirm", {ns: 'buttons'})}</div>
      </div>
    </div>
  )
}

export default AgePrompt;