import { useTranslation } from 'react-i18next';
import config from '../../app/config';
import EventsScheme from '../../features/events/eventsEnum';
import { formatDate } from '../../helpers';
import { DECLINED_PHASE, getEventPhase, GOING_PHASE, RESERVATION_PHASE, TAKEOVER_PHASE } from '../../helpers/eventPhases';
import { Image } from '../image';


const Event = (props: EventsScheme & { slug: string, onClick: () => void, bigger?: boolean }) => {
  const {t} = useTranslation(['general']);

  return (
    <div className={"event" + (props.bigger ? " bigger" : "")} onClick={props.onClick}>
      <DisplayEventState {...props} />
      <div className="event__banner">
        <Image src={config.assets + props.slug + props.banner} alt="" fadeIn />
      </div>
      <div className="event__info">
        <div className="eyebrow">{props.event_start && formatDate(new Date(props.event_start), 1, t)}</div>
        <h3 className="event__h3">{props.name}</h3>
      </div>
    </div>
  );
}

const DisplayEventState = (props: EventsScheme) => {
  let event_phase = getEventPhase(props.reservation_takeover, props.event_start, props.event_end, props.reservation_start, props.event_cancelled);
  const {t} = useTranslation(['status']);

  if(event_phase === DECLINED_PHASE)
    return <div className="event__cancelled">{t("declined_phase", {ns: 'status'})}</div>

  if(props.event_booked)
    return <div className="event__cancelled">{t("all_reserved", {ns: 'status'})}</div>

  // if reservations are opened
  if(event_phase === RESERVATION_PHASE)
    return <div className="event__going">{t("reservation_phase", {ns: 'status'})}</div>

  if(event_phase === GOING_PHASE || event_phase === TAKEOVER_PHASE)
    return <div className="event__going">{t("going_phase", {ns: 'status'})}</div>

  return <></>;
}

export default Event;