import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { placesAPI } from './placesAPI';
import PlacesScheme from './placesEnum';

// places scheme
interface placesState {
  place: PlacesScheme
}

// initial state
const initialState = {
  place: {},
} as placesState;

// get place data
export const getPlace = createAsyncThunk('place/get', placesAPI.get);


// create user slice
const placesSlice = createSlice({
  name: 'place',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getPlace.fulfilled, (state, { payload }) => {
      state.place = payload;
    })
  },
})


export default placesSlice.reducer;