import API from '../api';

// function to check if phone number is subscribed to specific club
export const isPhoneSubscribed = async (phone: string, club: string) => {
  try {
    let res = await API.get(`/newsletter/${club}/${phone}`);
    return res.data;
  } catch {
    return false;
  }
}

// function to check if phone number is subscribed to specific club
export const subscribePhone = async (phone: string, club: string) => {
  try {
    let res = await API.post(`/newsletter/${club}`, { query: { phone_number: phone } });
    return res.data;
  } catch {
    return false;
  }
}