export interface TableScheme {
  position: number[],
  rotation: number[],
  cameraOffset: number[],
  cameraAzimuth: number[], // [min, max]
  cameraPolar: number[], // [min, max]
  cameraMaxDistance: number,
  sprite: THREE.Sprite,
  mesh: THREE.Mesh,
  file: string,
  floor: number,
  id: number,
  label: string,
  color: number,
  section_id: number,
  type: number
}

export interface SectionScheme {
  id: number,
  section_name: string,
  section_camera_zoom?: number,
  section_camera_position: number[],
  section_camera_lookat: number[],
  section_camera_azimuth: number[], // [min, max]
  section_camera_polar: number[], // [min, max]
  section_camera_max_distance: number,
}

export interface ObjectScheme {
  position: number[],
  rotation: number[],
  mesh: THREE.Mesh,
  file: string,
  id: number,
  color: number,
  type: number,
  roughness: number,
  transmission: number,
  clearcoat: number
}

export default interface CameraScheme {
  id: number,
  place_id: number,
  position: number[],
  lookAt: number[],
  fov: number,
  near: number,
  far: number
}

export interface AvailableTablesTriggerScheme {
  table_id: number,
  old_table_id?: number,
  status: "process" | "delete" | "update" | "add"
}

export interface ReservedTablesScheme {
  process_tables: string[],
  tables: number[]
}

export interface MessageScheme {
  error?: string,
  success?: string,
  msg?: string,
  type?: string
}

export interface NewReservationScheme {
  table_id?: string | number, 
  customer_name: string, 
  phone_number: string,
  group_size?: number,
  age_average?: number,
  note?: string,
  section_id?: number
}

export interface ServerToClientEvents {
  // init
  init: (data: ReservedTablesScheme) => void,
  // poruka koju ti vrati triba prikopirat sa dashboarda iste poruke.
  message: (data: MessageScheme) => void,
  // dostupni stolovi kako se salju
  available_tables: (data: AvailableTablesTriggerScheme) => void
  // poruka poslana klijentu uspjesno/neuspjesno
  success_sms: (data: {success: boolean}) => void
  // nakon uspjesno poslane poruke, ukoliko istekne 2 minute vraca success: false ili ukoliko je 3 puta sms kod upisan (message ti vraca poruke), ukoliko je true rezervacija je uspjesna
  reservation_success: (data: {success: boolean, link: string}) => void,
  // on session change
  new_session_key: (data: { new_session_key: string }) => void
}

export interface ClientToServerEvents {
  // zahtjev za rezervacijom
  new_reservation: (data: NewReservationScheme) => void,
  // sms code ( kod unosa smsa )
  sms_code: (data: {sms_code: string}) => void,
  // abort reservations
  abort: () => void
}

export const MATERIAL = {
  PHYSICAL: 1,
  STANDARD: 0
}