import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { currencyAPI } from './currencyAPI';

const initialState = {
  currencies: {} as Record<string, {symbol: string, default: boolean}>
}

// get currencies thunk
export const getCurrencies = createAsyncThunk('currency/get', currencyAPI.get);

// create user slice
export const currencySlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrencies.fulfilled, (state, { payload  }) => {
      // insert into state currencies
      state.currencies = payload as any;
    })
  },
})

export default currencySlice.reducer;