// FAZA PRIJE REZERVACIJA -> SAMO LIMIT TABLES 
export const DISPLAY_PHASE = 0; 
// FAZA REZERVACIJA -> MOŽE SE REZERVIRATI, OTKAZATI, RADITI IZMJENE
export const RESERVATION_PHASE = 1;
// FAZA DOLAZAKA GOSTIJU -> MOŽE SE POTVRDITI DOLAZAK, REZERVIRATI; OTKAZATI, RADITI IZMJENE, OZNAČITI STOL
export const TAKEOVER_PHASE = 2;
// FAZA TRAJANJA DOGAĐAJA -> MOŽE SE POTVRIDITI DOLAZAK, RADITI IZMJENE, OZNAČITI STOL ( rezervira se priko limit tables-a )
export const GOING_PHASE = 3;
// DOGAĐAJ GOTOV - > NIŠTA
export const END_PHASE = 4;
// DOGAĐAJ OTKAZAN
export const DECLINED_PHASE = 5;

export const getEventPhase = (reservation_takeover: Date, event_start: Date, event_end: Date, reservation_start: Date, event_cancelled: boolean) => {
  // event cancelled
  if(event_cancelled)
    return DECLINED_PHASE;
  let current_date = new Date();
  // if current date is smaller than reservation start it is display phase
  if(current_date < new Date(reservation_start))
    return DISPLAY_PHASE;
  // if current date is bigger and equal than reservation start and it is smaller than event start time
  else if(current_date >= new Date(reservation_start) && current_date < new Date(event_start))
    return RESERVATION_PHASE;
  // if current date is bigger or equal of event start time and smaller than reservation takeover it is takeover phase
  else if(current_date >= new Date(event_start) && current_date < new Date(reservation_takeover))
    return TAKEOVER_PHASE;
  // if current date is bigger or equal than event_start and smaller than event_end
  else if(current_date >= new Date(event_start) && current_date < new Date(event_end))
    return GOING_PHASE;
  // else it is end phase
  else 
    return END_PHASE;
}

