import * as THREE from 'three';
import { BufferGeometry } from 'three';
import { _format, _load, loadObject } from '../../helpers';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import config from '../../app/config';
import PlacesScheme from '../../features/places/placesEnum';
import TWEEN, { Tween } from '@tweenjs/tween.js';
import THREEx from './threex.domevents';
import { io, Socket } from "socket.io-client";
import CameraScheme, { TableScheme, ReservedTablesScheme, ServerToClientEvents, ClientToServerEvents, AvailableTablesTriggerScheme, MessageScheme, ObjectScheme, MATERIAL, SectionScheme } from "./enums";
import { shuffle } from '../../helpers/arrayShuffle';
import EventsScheme from '../../features/events/eventsEnum';

const LIMITED_COLOR = new THREE.Color(0xA21414);
const DARK_LIMITED_COLOR = new THREE.Color(0x541919);
const NORMAL_COLOR = new THREE.Color(0x666666);
const WHITE_COLOR = new THREE.Color(0xFFFFFF);
const RESERVED_COLOR = new THREE.Color(0xA21414); // 0xffed98 7B9E89
const VIP_TABLE_COLOR = new THREE.Color(0xffed98)
const IN_PROGRESS_COLOR = new THREE.Color(0x363431);
const SELECTED_COLOR = new THREE.Color(0xFFFFFF); // 0x33FFDA 80FFE8 // 00CCA7
const LEAD_COLOR = new THREE.Color(0x1AC87C); // 80FFE8 // 00CCA7
const PRESENCE_COLOR = new THREE.Color(0x222222);

class CanvasActions {
  scene = new THREE.Scene();
  camera: THREE.PerspectiveCamera;
  canvas: HTMLCanvasElement;
  controls: OrbitControls;
  width: number;
  height: number;
  renderer: THREE.WebGLRenderer;
  tables: Record<string, TableScheme>;
  sections: Record<string, SectionScheme>;
  objects: Record<string, ObjectScheme>;
  geometries: Record<string, BufferGeometry>;
  club: PlacesScheme;
  event_id: number | undefined;
  event: EventsScheme | undefined;
  locale: THREE.Mesh;
  domEvents: THREEx.DomEvents;
  onLoaded: () => void;
  openScanner: () => void;
  onLimit: (id: number) => void;
  toggleForm: (state: boolean) => void;
  onError: (error: MessageScheme) => void;
  noTables: () => void;
  noSections: () => void;
  loadingSocket: () => void;
  sendMessage: (data: MessageScheme) => void;
  reservationsStatus: (status: string, link?: string) => void;
  updateTableState: (tableID: number, currentTableIndex: number, availableTablesNumber: number, section?: { sectionID: number, sectionName: string }) => void;
  formState: boolean;
  socket: Socket<ServerToClientEvents, ClientToServerEvents> | undefined;
  sessionKey: string | undefined;
  disconnectSocketManually: boolean;
  selectedTable: number;
  availableTables: string[];
  currentTable: number;
  currentSection: number;
  leading: number | null;
  listenersLoaded: boolean;
  lastPosition: Record<string, number>;
  cameraSetup: CameraScheme | undefined;
  tweenTable: {t1: Tween<THREE.Vector3> | undefined, t2: Tween<Record<string, number>> | undefined, runned: boolean};
  tweenSection: {t1: Tween<THREE.Vector3> | undefined, t2: Tween<Record<string, number>> | undefined, t3: Tween<{ zoom: number; }> | undefined, runned: boolean};
  tweenInitial: {t1: Tween<THREE.Vector3> | undefined, t2: Tween<Record<string, number>> | undefined, t3: Tween<{ zoom: number; }> | undefined, runned: boolean};

  constructor(canvas: HTMLCanvasElement, club: PlacesScheme) {
    this.club = club;
    this.event_id = undefined;
    this.event = undefined;
    this.canvas = canvas;
    this.width = canvas.clientWidth;
    this.height = canvas.clientHeight;
    this.tables = {};
    this.sections = {};
    this.objects = {};
    this.geometries = {};
    this.locale = new THREE.Mesh();
    this.socket = undefined;
    this.sessionKey = undefined;
    this.disconnectSocketManually = false;
    this.onLoaded = () => {};
    this.openScanner = () => {};
    this.onLimit = () => {};
    this.onError = () => {};
    this.noTables = () => {};
    this.noSections = () => {};
    this.loadingSocket = () => {};
    this.sendMessage = () => {};
    this.toggleForm = () => {};
    this.updateTableState = () => {};
    this.reservationsStatus = () => {};
    this.formState = false;
    this.selectedTable = -1;
    this.availableTables = [];
    this.currentTable = 0;
    this.currentSection = 0;
    this.leading = null;
    this.listenersLoaded = false;
    this.lastPosition = { x: -43, y: 0, z: 80 };
    this.tweenTable = {t1: undefined, t2: undefined, runned: false};
    this.tweenSection = {t1: undefined, t2: undefined, t3: undefined, runned: false};
    this.tweenInitial = {t1: undefined, t2: undefined, t3: undefined, runned: false};
    this.cameraSetup = undefined;
    this.renderer = new THREE.WebGLRenderer();
    this.camera = new THREE.PerspectiveCamera();
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.domEvents = new THREEx.DomEvents();
    // setup scene background
    this.scene.background = new THREE.Color( 0x1C1B19 );
    // load everything up
    this.init();
  }

  // function to load and setup everything
  async init() {
    try {
      // setup camera
      await this.setupCamera();
      // setup renderer
      this.setupRenderer();
      // register dom events on camera and renderer
      this.domEvents = new THREEx.DomEvents(this.camera, this.renderer.domElement)
      // setup orbit controls
      this.controls = this.setupControls();
      // load and add locale to the scene
      await this.loadLocale();
      // load and setup lights
      await this.setupLights();
      // load tables firstly
      await this.loadTables();
      // load objects
      await this.loadObjects();
      // then load table geometries
      await this.loadGeometries();
      // and then draw tables on the canvas
      await this.firstRender();
      // setup initial camera look at
      this.camera.lookAt(new THREE.Vector3(this.lastPosition.x, this.lastPosition.y, this.lastPosition.z));
      // render the scene for the end
      this.renderer.render(this.scene, this.camera);
      // show canvas and remove loader
      this.onLoaded();
    } catch {
      throw new Error("Error with canvas")
    }
  }

  // function to setup camera
  async setupCamera() {
    // load camera setup from server
    this.cameraSetup = await _load(`entertainment/camera/${this.club.slug}`);
    // protection
    if(!this.cameraSetup) return;
    // create perspective camera
    this.camera = new THREE.PerspectiveCamera( this.cameraSetup.fov, window.innerWidth / window.innerHeight, this.cameraSetup.near, this.cameraSetup.far );
    this.camera.position.set(this.cameraSetup.position[0], this.cameraSetup.position[1], this.cameraSetup.position[2]);
    // set last position
    this.lastPosition = {
      x: this.cameraSetup.lookAt[0],
      y: this.cameraSetup.lookAt[1],
      z: this.cameraSetup.lookAt[2]
    }
  }

  // function to setup renderer
  setupRenderer() {
    // add event listener on webgl conext lost
    this.canvas.addEventListener('webglcontextlost', (event) => {
      // reset renderer state
      this.renderer.state.reset();
      // dispose all objects
      this.renderer.dispose();
      // reload page
      window.location.reload();
    }, false);
    this.renderer = new THREE.WebGLRenderer({ canvas: this.canvas, antialias: true });
    this.renderer.setSize(this.width, this.height);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.render(this.scene, this.camera);
    // add event listener on screen resize (canvas is full screen)
    window.addEventListener('resize', () => {
      // update camera aspect ratio to new screen width and height
      this.camera.aspect = window.innerWidth / window.innerHeight;
      // update projection matrix
      this.camera.updateProjectionMatrix();
      // set renderer size to new values of window width and height
      this.renderer.setSize( window.innerWidth, window.innerHeight );
      // re-render the scene
      this.renderer.render(this.scene, this.camera);
    })
  }

  // function to set event
  setEvent(event_id: number, event: EventsScheme) {
    // opening socket only when different event is picked
    if(this.event_id !== event_id) {
      // store event
      this.event = event;
      // if socket is already opened, close it
      if(this.event_id) {
        // close socket
        this.socket!.close();
        // load socket listeners again
        this.listenersLoaded = false;
      }
      // get session from storage
      let storedSession = localStorage.getItem('session_key');
      // if session was stored
      if(storedSession) {
        // extract stored event id and stored session key
        var [storedEventId, storedSessionKey] = String(storedSession).split('.').slice(0, 2);
        // is the same event selected as the stored one, if is send stored session key on socket auth
        if(parseInt(storedEventId) === event_id)
          this.sessionKey = storedSessionKey;
      }
      // set event
      this.event_id = event_id;
      // connect to the socket
      this.socket = io(config.socket, {
        transports: ["websocket"],
        auth: {
          club: this.club.slug,
          event_id: this.event_id || '',
          session_key: this.sessionKey
        }
      });
      // attach socket listeners
      this.initSocket();
    } else {
      // animate to the first available table
      if(this.disconnectSocketManually){
        // update socket manager (when we connect to the same event)
        this.socket!.auth = { ...this.socket!.auth, session_key: undefined };
        // reconnect to socket when you enter the same event
        this.socket && this.socket.connect();
      }
    }
    // make sure this variable is set to false when you enter event again
    this.disconnectSocketManually = false;
  }

  // function to initiate choose sections mode
  async chooseSection(event_id: number, event: EventsScheme) {
    // store event
    this.event = event;
    // fetch all available sections
    this.sections = _format(await _load(`sections/${this.club.slug}/event/${event_id}`), 'id');
    // protection
    if(!Object.keys(this.sections).length)
      return this.noSections()
    // return table colors to gray and paint unavailable sections to red
    this.prepareTablesInSectionMode()
    // select random section
    this.currentSection = Math.floor(Math.random()*Object.keys(this.sections).length + 1);
    // change current table number on controller
    this.updateTableState(-1, 0, 0, { sectionID: parseInt(Object.keys(this.sections)[this.currentSection - 1]), sectionName: this.sections[Object.keys(this.sections)[this.currentSection - 1]].section_name });
    // terminate all tweens
    this.terminateTweensExceptOne('all');
    // animate to section
    this.animateToSection(parseInt(Object.keys(this.sections)[this.currentSection - 1]), 1100);
    // blink tables in selected section
    this.blinkTablesInSection(false);
  }

  // function to setup orbit controls
  setupControls() {
    let controls = new OrbitControls( this.camera, this.renderer.domElement );
    controls.target = new THREE.Vector3( this.lastPosition.x, this.lastPosition.y, this.lastPosition.z );
    // controls.enableRotate = false;
    controls.enableZoom = true;
    // left mouse click used for moving as on 2d map, wheel and right click are not needed
    controls.mouseButtons = { LEFT: THREE.MOUSE.ROTATE, MIDDLE: THREE.MOUSE.DOLLY, RIGHT: THREE.MOUSE.ROTATE };
    // one finger is used for moving verticaly or horizontaly, and 2 fingers can do the same and also zoom in or out
    controls.touches = { ONE: THREE.TOUCH.ROTATE, TWO: THREE.TOUCH.DOLLY_PAN };
    // disable pan
    controls.enablePan = false;
    // add event listener on change to re-render the scene
    controls.addEventListener('change', () => this.renderer.render(this.scene, this.camera));
    return controls;
  }

  // function to load locale
  async loadLocale() {
    // load interior
    let interior = await _load(`entertainment/interior/${this.club.slug}`);
    // load locale geometry
    let geometry = await loadObject(`${config.assets}${this.club.slug}${interior.file}`);
    // create new mesh from geometry
    this.locale = new THREE.Mesh(geometry, new THREE.MeshStandardMaterial({ color: interior.color }));
    // set locale position
    this.locale.position.set(interior.position[0], interior.position[1], interior.position[2]);
    // set locale rotation
    this.locale.rotation.set(interior.rotation[0], interior.rotation[1], interior.rotation[2]);
    // compute vertices, make mesh smoother
    this.locale.traverse(child => {
      if( child instanceof THREE.Mesh )
        child.geometry.computeVertexNormals();
    });
    // add locale to the scene
    this.scene.add(this.locale);
  }

  // function to load and setup lights
  async setupLights() {
    let lights = await _load(`lights/${this.club.slug}`);
    for(let light of lights){
      light.val = new THREE.DirectionalLight( light.color, light.intensity );
      // light.val = new THREE.DirectionalLight( 0xffffff, .5 );
      light.val.position.set(...light.position);
      // light.val.lookAt(...light.lookAt);
      this.scene.add(light.val);
    }
  }

  // function to load tables configuration from API
  async loadTables() {
    let tables = _format(await _load(`tables/${this.club.slug}`), 'id');
    this.tables = tables;
  }
  
  // function to load club specific objects
  async loadObjects() {
    let objects = _format(await _load(`objects/${this.club.slug}`), 'id');
    this.objects = objects;
  }

  // function to load table geometries
  async loadGeometries() {
    // load table geometries
    await Promise.all(Object.values(this.tables).map(async (obj) => {
      this.geometries[obj.id] = await loadObject(`${config.assets}${this.club.slug}${obj.file}`);
    }));
    // load objects geometries
    await Promise.all(Object.values(this.objects).map(async (obj) => {
      this.geometries["detail/" + obj.id] = await loadObject(`${config.assets}${this.club.slug}${obj.file}`);
    }));
  }

  // function to create THREE.Mesh and match it with table foreach table, and draw tables on the scene
  async firstRender() {
    for(let obj of Object.values(this.tables)){
      // create new mesh from geometry
      obj.mesh = new THREE.Mesh(this.geometries[obj.id], new THREE.MeshStandardMaterial({ color: obj.color }));
      // set table position
      obj.mesh.position.set(obj.position[0], obj.position[1], obj.position[2]);
      // set table rotation
      obj.mesh.rotation.set(obj.rotation[0], obj.rotation[1], obj.rotation[2]);
      // compute vertices, make mesh smoother
      obj.mesh.traverse(child => {
        if( child instanceof THREE.Mesh )
          child.geometry.computeVertexNormals();
      });
      // set object id as mesh name so we can remove it easily
      obj.mesh.name = `${obj.id}`;
      // add table to the scene
      this.scene.add(obj.mesh);
    }
    // draw club specific objects into the scene
    for(let obj of Object.values(this.objects)) {
      // create new mesh from geometry
      obj.mesh = new THREE.Mesh(this.geometries["detail/" + obj.id], this.getObjectMaterial(obj));
      // set detail position
      obj.mesh.position.set(obj.position[0], obj.position[1], obj.position[2]);
      // set detail rotation
      obj.mesh.rotation.set(obj.rotation[0], obj.rotation[1], obj.rotation[2]);
      // compute vertices, make mesh smoother
      obj.mesh.traverse(child => {
        if( child instanceof THREE.Mesh )
          child.geometry.computeVertexNormals();
      });
      // set object id as mesh name so we can remove it easily
      obj.mesh.name = `detail/${obj.id}`;
      // add detail to the scene
      this.scene.add(obj.mesh);
    }
    // this.renderer.compile(this.scene, this.camera);
  }

  // function that returns object material based on object type and other specified fields
  getObjectMaterial(obj: ObjectScheme) {
    if(obj.type === MATERIAL.STANDARD)
      return  new THREE.MeshStandardMaterial({ color: obj.color })
    else if(obj.type === MATERIAL.PHYSICAL)
      return new THREE.MeshPhysicalMaterial({  
        roughness: obj.roughness,  
        transmission: obj.transmission,
        clearcoat: obj.clearcoat,
        color: obj.color
      })
  }

  // function to attach socket listeners
  initSocket() {
    // check if listeners are already loaded (on socket reconnect, bypass duplication of listeners)
    if(this.listenersLoaded) return;
    this.socket!.on("connect", () => {
      // terminate twins
      this.terminateTweensExceptOne('table');
      // set state as loaded
      this.onLoaded();
    });
    this.socket!.on("init", data => this.displayReservedTables(data));
    this.socket!.on("new_session_key", data => this.onSessionChange(data));
    this.socket!.on("message", data => this.onMessage(data));
    this.socket!.on("available_tables", data => this.onReservationTrigger(data));
    this.socket!.on("success_sms", data => this.successSMS(data));
    this.socket!.on("reservation_success", data => this.onSuccessReservation(data));
    this.socket!.on("disconnect", () => {
      if(!this.disconnectSocketManually){
        this.loadingSocket();
      } else {
        // user destroyed session manually
        this.sessionKey = undefined;
        localStorage.removeItem('session_key');
      }
    });
    // set listeners loaded flag
    this.listenersLoaded = true
  }

  // function to update sessionKey
  onSessionChange(data: { new_session_key: string }) {
    // update socket manager
    this.socket!.auth = { ...this.socket!.auth, session_key: data.new_session_key };
    // store socket id to variable
    this.sessionKey = data.new_session_key;
    // store socket id also to local storage
    localStorage.setItem('session_key', `${this.event_id}.${data.new_session_key}`);
  }

  // trigger function to handle server status message
  onMessage(data: MessageScheme) {
    if(data.error)
      this.onError(data);
  }

  // trigger function on sms message delivery status
  successSMS(data: { success: boolean }) {
    if(!data.success) {
      this.reservationsStatus("failed");
        // remove selected table
      this.selectedTable = -1;
    } else
      this.openScanner();
  }

  // trigger function on reservation status
  onSuccessReservation(data: { success: boolean, link: string }) {
    if(!data.success)
      this.reservationsStatus("failed", "");
    else
      this.reservationsStatus("success", data.link);
    // remove selected table
    this.selectedTable = -1;
    // after reservation process ends, remove sessionKey from storage
    this.sessionKey = undefined;
    localStorage.removeItem('session_key');
  }

  // function to abort reservation process
  abort() {
    this.socket!.emit("abort");
  }

  // function to handle new reservation request
  newReservation(data: { name: string, phone: string, selected_table: any }) {
    // emit new reservation
    this.socket!.emit("new_reservation", { table_id: String(data.selected_table), customer_name: data.name, phone_number: data.phone });
    // store selected table
    this.selectedTable = data.selected_table;
  }
  
  // function to check if valid code from sms is entered
  onSMSCode(sms_code: string) {
    this.socket!.emit("sms_code", { sms_code: sms_code });
  }

  // clear old data from working variables
  clearWorkingVariables() {
    // remove all event listeners from available tables
    if(this.availableTables.length)
      for(let table_id of this.availableTables){
        // remove it's event listeners
        this.domEvents.removeEventListener(this.tables[table_id].mesh, 'click', this.onTableClick.bind(this), false)
        this.domEvents.removeEventListener(this.tables[table_id].mesh, 'touchstart', this.onTableClick.bind(this), false)
      }
    // return variables to default value
    this.availableTables = [];
    this.currentTable = 1;
  }

  // function to prepare tables color for choose section mode
  prepareTablesInSectionMode() {
    // paint tables
    for(let table of Object.values(this.tables)) {
      // check if table section is reserved and paint current table to red, else paint it to gray
      if(Object.keys(this.sections).indexOf(String(table.section_id)) === -1)
        this.paintTable(table.id, RESERVED_COLOR);
      else
        this.paintTable(table.id, NORMAL_COLOR);
    }
  }

  // function to display reserved tables
  displayReservedTables(data: ReservedTablesScheme) {
    const { tables, process_tables } = data;
    // return variables to default value
    this.clearWorkingVariables();
    // loop through all tables
    for(let table of Object.values(this.tables)) {
      let isReserved = tables.indexOf(table.id) !== -1;
      let isInReservation = process_tables.indexOf(String(table.id)) !== -1;
      // conditions
      if(!isReserved && !isInReservation) {
        this.availableTables.push(String(table.id));
        // available tables paint to normal color, and VIP tables to gold
        this.paintByType(table.id);
        // add event listener on available table click and touch for mobiles
        this.domEvents.addEventListener(table.mesh, 'click', this.onTableClick.bind(this), false);
        this.domEvents.addEventListener(table.mesh, 'touchstart', this.onTableClick.bind(this), false);
      } else if(isReserved) {
        // paint table
        this.paintTable(table.id, RESERVED_COLOR);
      } else if(isInReservation) {
        // paint table
        this.paintTable(table.id, IN_PROGRESS_COLOR);
      }
    }
    // shuffle available tables
    shuffle(this.availableTables);
    // set available and current table numbers
    this.updateTableState(parseInt(this.availableTables[this.currentTable - 1]), this.currentTable, this.availableTables.length);
    // re-render the scene (maybe not needed, remove later)
    this.renderer.render(this.scene, this.camera);
    // animate to the first available table
    this.animateToTable(parseInt(this.availableTables[this.currentTable - 1]), 1100);
  }

  onReservationTrigger(data: AvailableTablesTriggerScheme) {
    // vamo kasnije dodat provjeru znaci ukoliko se korisnik nalazi na ovom stolu da ga makne s njega ( samo process pazit jer to moze bit tvoj stol )
    const { table_id, status } = data;
    // decide action based on status
    if(status === 'add') {
      // remove from available tables
      this.removeAvailableTable(String(table_id));
      // paint table
      this.paintTable(table_id, RESERVED_COLOR);
    } else if(status === 'update') {
      const { old_table_id } = data;
      // remove new table from available tables
      this.removeAvailableTable(String(table_id));
      // paint new table
      this.paintTable(table_id, RESERVED_COLOR);

      // if there is old_table_id defined
      if(!!old_table_id) {
        // assign old_table id to available
        this.availableTables.push(String(old_table_id));
        // add it's event listeners
        this.domEvents.addEventListener(this.tables[old_table_id].mesh, 'click', this.onTableClick.bind(this), false)
        this.domEvents.addEventListener(this.tables[old_table_id].mesh, 'touchstart', this.onTableClick.bind(this), false)
        // paint table
        this.paintByType(old_table_id);
      }
    } else if(status === 'delete') {
      // set table to free
      this.availableTables.push(String(table_id));
      // add it's event listeners
      this.domEvents.addEventListener(this.tables[table_id].mesh, 'click', this.onTableClick.bind(this), false)
      this.domEvents.addEventListener(this.tables[table_id].mesh, 'touchstart', this.onTableClick.bind(this), false)
      // paint table
      this.paintByType(table_id);
    } else if(status === "process") {
      // remove from available tables
      this.removeAvailableTable(String(table_id));
      // paint table
      this.paintTable(table_id, IN_PROGRESS_COLOR);
    }
    // change current table number on controller
    this.updateTableState(parseInt(this.availableTables[this.currentTable - 1]), this.currentTable, this.availableTables.length);
    // re-render the scene
    this.renderer.render(this.scene, this.camera);
    // set available tables
    //this.setAvailableTables(this.availableTables);
  }

  handleSectionControllerAction(direction: string) {
    // already animation protection
    if(this.tweenInitial.runned || this.tweenTable.runned || this.tweenSection.runned) return;
    // return currently painted section to normal color
    if(this.currentSection !== 0) {
      // blink tables in selected section
      for(let table of Object.values(this.tables))
        if(table.section_id === this.sections[Object.keys(this.sections)[this.currentSection - 1]].id)
          this.paintTable(table.id, NORMAL_COLOR);
    }
    // handle direction
    if(direction === "next") {
      if(this.currentSection < Object.keys(this.sections).length)
        this.currentSection++;
      else
        this.currentSection = 1;
    } else {
      if(this.currentSection > 1)
        this.currentSection--;
      else
        this.currentSection = Object.keys(this.sections).length
    }
    // change current table number on controller
    this.updateTableState(-1, 0, 0, { sectionID: parseInt(Object.keys(this.sections)[this.currentSection - 1]), sectionName: this.sections[Object.keys(this.sections)[this.currentSection - 1]].section_name });
    // animate
    this.animateToSection(parseInt(Object.keys(this.sections)[this.currentSection - 1]), 1100);
    // re-render the scene
    this.renderer.render(this.scene, this.camera);
  }

  // function to handle click on controller action
  handleControllerAction(direction: string) {
    // already animation protection
    if(this.tweenInitial.runned || this.tweenTable.runned || this.tweenSection.runned) return;
    // return current table to normal color
    if(direction !== "auto")
      this.paintByType(parseInt(this.availableTables[this.currentTable - 1]));
    // check is there any available table
    if(!this.availableTables.length) {
      // display 0/0 available tables
      this.updateTableState(-1, 0, 0);
      // remove from 3d view
      this.noTables();
      // don't go further
      return;
    }
    // increment or decrement depending on direction
    if(direction === "next" || direction === "auto")
      if(this.currentTable < this.availableTables.length)
        this.currentTable++;
      else
        this.currentTable = 1;
    else
      if(this.currentTable > 1)
        this.currentTable--;
      else
        this.currentTable = this.availableTables.length;

    // change current table number on controller
    this.updateTableState(parseInt(this.availableTables[this.currentTable - 1]), this.currentTable, this.availableTables.length);
    // animate
    this.animateToTable(parseInt(this.availableTables[this.currentTable - 1]), 1100);
    // re-render the scene
    this.renderer.render(this.scene, this.camera);
  }

  terminateTweensExceptOne(exception: 'table' | 'section' | 'initial' | 'all') {
    // terminate tween for table if it is not exception
    if(this.tweenTable.runned && exception !== 'table') {
      if(this.tweenTable.t1)
        this.tweenTable.t1.stop();
      if(this.tweenTable.t2)
        this.tweenTable.t2.stop();
      this.tweenTable.runned = false;
    }
    // terminate tween for section if it is not exception
    if(this.tweenSection.runned && exception !== 'section') {
      if(this.tweenSection.t1)
        this.tweenSection.t1.stop();
      if(this.tweenSection.t2)
        this.tweenSection.t2.stop();
      if(this.tweenSection.t3)
        this.tweenSection.t3.stop();
      this.tweenSection.runned = false;
    }
    // terminate tween for initial if it is not exception
    if(this.tweenInitial.runned && exception !== 'initial') {
      if(this.tweenInitial.t1)
        this.tweenInitial.t1.stop();
      if(this.tweenInitial.t2)
        this.tweenInitial.t2.stop();
      if(this.tweenInitial.t3)
        this.tweenInitial.t3.stop();
      this.tweenInitial.runned = false;
    }
  }

  animateToTable(tableID: number, duration: number) {
    if(this.tweenInitial.runned || this.tweenTable.runned || this.tweenSection.runned) return;
    // set tween only to table
    this.terminateTweensExceptOne('table');
    this.tweenTable.runned = true;
    // calculate new camera position
    let newCameraPosition = this.calculateCameraPosition(tableID);
    // set new lookAt position
    let newCameraLookAt = this.getPositionObject(this.tables[tableID].position);
    // lift look up (don't look at table legs)
    newCameraLookAt.y += 40;
    // get current lookAt position
    let currentCameraLookAt = this.lastPosition;
    // paint newly selected table to selected color, if table is VIP - leave gold color
    if(this.tables[this.availableTables[this.currentTable - 1]].type !== 1)
      this.paintTable(parseInt(this.availableTables[this.currentTable - 1]), SELECTED_COLOR);
    // this as self
    let self = this;
    // Tween animation for camera position
    this.tweenTable.t1 = new TWEEN.Tween(this.camera.position).to(newCameraPosition, duration).easing(TWEEN.Easing.Quadratic.InOut).onUpdate(function () {
      // update camera position on each step
    }).start();
    // Tween animation for lookAt position
    this.tweenTable.t2  = new TWEEN.Tween(currentCameraLookAt).to(newCameraLookAt, duration).easing(TWEEN.Easing.Quadratic.InOut).onUpdate(function () {
      // update camera lookAt Vector
      self.camera.lookAt(new THREE.Vector3(currentCameraLookAt.x, currentCameraLookAt.y, currentCameraLookAt.z));
    }).onComplete(() => { 
      // set badge as false when animation is completed (stop re-rendering)
      this.tweenTable.runned = false;
    }).start();
    this.controls.target.set( newCameraLookAt.x, newCameraLookAt.y, newCameraLookAt.z );
    // set controls restrictions for newly selected table
    this.controls.minPolarAngle = this.tables[tableID].cameraPolar[0];
    this.controls.maxPolarAngle = this.tables[tableID].cameraPolar[1];
    this.controls.minAzimuthAngle = this.tables[tableID].cameraAzimuth[0];
    this.controls.maxAzimuthAngle = this.tables[tableID].cameraAzimuth[1];
    this.controls.maxDistance = this.tables[tableID].cameraMaxDistance;
    // start animation (re-rendering proccess)
    requestAnimationFrame(this.animateCamera.bind(this));
    // set current lookAt position as last position
    this.lastPosition = newCameraLookAt;
  }

  animateToSection(sectionID: number, duration: number) {
    if(this.tweenInitial.runned || this.tweenTable.runned || this.tweenSection.runned) return;
    // set tween only to table
    this.terminateTweensExceptOne('section');
    this.tweenSection.runned = true;
    // calculate new camera position
    let newCameraPosition = this.getPositionObject(this.sections[sectionID].section_camera_position || [0,0,0]);
    // set new lookAt position
    let newCameraLookAt = this.getPositionObject(this.sections[sectionID].section_camera_lookat || [0,0,0]);
    // lift look up (don't look at table legs)
    newCameraLookAt.y += 40;
    // get current lookAt position
    let currentCameraLookAt = this.lastPosition;
    // this as self
    let self = this;
    // Tween animation for camera position
    this.tweenSection.t1 = new TWEEN.Tween(this.camera.position).to(newCameraPosition, duration).easing(TWEEN.Easing.Quadratic.InOut).onUpdate(function () {
      // update camera position on each step
    }).start();
    // Tween animation for camera zoom
    let zoom = { zoom: this.camera.zoom }
    if(this.camera.zoom !== this.sections[sectionID].section_camera_zoom)
      this.tweenSection.t3 = new TWEEN.Tween(zoom).to({ zoom: (this.sections[sectionID].section_camera_zoom || 1)}, duration).easing(TWEEN.Easing.Quadratic.InOut).onUpdate(function () {
        // update camera position on each step
        self.camera.zoom = zoom.zoom;
        self.camera.updateProjectionMatrix();
      }).start();
    // Tween animation for lookAt position
    this.tweenSection.t2 = new TWEEN.Tween(currentCameraLookAt).to(newCameraLookAt, duration).easing(TWEEN.Easing.Quadratic.InOut).onUpdate(function () {
      // update camera lookAt Vector
      self.camera.lookAt(new THREE.Vector3(currentCameraLookAt.x, currentCameraLookAt.y, currentCameraLookAt.z));
    }).onComplete(() => {
      // set badge as false when animation is completed (stop re-rendering)
      this.tweenSection.runned = false;
    }).start();
    this.controls.target.set( newCameraLookAt.x, newCameraLookAt.y, newCameraLookAt.z );
    // set controls restrictions for newly selected table
    this.controls.minPolarAngle = this.sections[sectionID].section_camera_polar[0];
    this.controls.maxPolarAngle = this.sections[sectionID].section_camera_polar[1];
    this.controls.minAzimuthAngle = this.sections[sectionID].section_camera_azimuth[0];
    this.controls.maxAzimuthAngle = this.sections[sectionID].section_camera_azimuth[1];
    this.controls.maxDistance = this.sections[sectionID].section_camera_max_distance;
    // start animation (re-rendering proccess)
    requestAnimationFrame(this.animateCamera.bind(this));
    // set current lookAt position as last position
    this.lastPosition = newCameraLookAt;
  }

  // function to blink table from selected section
  blinkTablesInSection(returnPeriod?: boolean) {
    // protection
    if(this.currentSection === 0) return;
    // blink tables in selected section
    for(let table of Object.values(this.tables)) {
      if(table.section_id === this.sections[Object.keys(this.sections)[this.currentSection - 1]].id) {
        if(!returnPeriod)
          this.paintTable(table.id, SELECTED_COLOR);
        else
          this.paintTable(table.id, NORMAL_COLOR);
      }
    }
    // re-render the scene
    this.renderer.render(this.scene, this.camera);
    // animate
    setTimeout(() => requestAnimationFrame(this.blinkTablesInSection.bind(this, !returnPeriod)), 500);
  }

  // handle click and touch on any available table
  onTableClick(e: any) {
    // stop propagation
    e.stopPropagation();
    // based on event mode
    if(this.event && this.event.event_mode === 'choose_section')
      return;
    // jump to the table (animation)
    this.jumpToTable(e.target.name)
  }

  // function to jump to the available table when clicked on one
  jumpToTable(tableID: number) {
    // already animation protection
    if(this.tweenInitial.runned || this.tweenTable.runned || this.tweenSection.runned) return;
    // get index of table in available tables array
    let index = this.availableTables.indexOf(String(tableID));
    // protection
    if(index === -1 || this.currentTable === index + 1) return;
    // return current table to normal color
    this.paintByType(parseInt(this.availableTables[this.currentTable - 1]));
    // set new current table index
    this.currentTable = index + 1;
    // change current table number on controller
    this.updateTableState(parseInt(this.availableTables[this.currentTable - 1]), this.currentTable, this.availableTables.length);
    // animate to the table
    this.animateToTable(tableID, 1100);
  }

  // function to re-render scene and update animations (exit dependency on "badge"[boolean] variable)
  animateCamera() {
    TWEEN.update();
    this.renderer.render(this.scene, this.camera);
    if(this.tweenInitial.runned || this.tweenTable.runned || this.tweenSection.runned)
      requestAnimationFrame(this.animateCamera.bind(this));
  }

  // function that parses position array to position object
  getPositionObject(position: number[]) {
    return {
      x: position[0],
      y: position[1],
      z: position[2]
    }
  }

  // function to calculate camera position for given table
  calculateCameraPosition(tableID: number) {
    // get table position
    let newCameraPosition = Object.assign({}, this.tables[tableID].position);
    // add camera offset from given table
    for(let i = 0; i < 3; i++) 
      newCameraPosition[i] += this.tables[tableID].cameraOffset[i];

    // return position object of new camera
    return this.getPositionObject(newCameraPosition);
  }

  // function to remove from available tables array
  removeAvailableTable(table_id: string) {
    // find it's index
    let index = this.availableTables.indexOf(table_id);
    // check if is in array
    if(index !== -1) {
      // store before removing from array
      let currentTable = this.availableTables[this.currentTable - 1];
      // remove it's event listeners
      this.domEvents.removeEventListener(this.tables[table_id].mesh, 'click', this.onTableClick.bind(this), false)
      this.domEvents.removeEventListener(this.tables[table_id].mesh, 'touchstart', this.onTableClick.bind(this), false)
      // remove from array
      this.availableTables.splice(index, 1);
      // return if I'm am the one that started reservation first
      if(String(this.selectedTable) == table_id) return;
      // if guest is looking in this table, remove him
      if(currentTable == table_id) {
        this.handleControllerAction("auto");
        // dispatch message that someone was faster with reservation
        this.sendMessage({ type: "error", msg: "faster_with_reservation" });
      }
    }
  }

  paintByType(table_id: number) {
    if(this.tables[table_id].type === 1)
      this.paintTable(table_id, VIP_TABLE_COLOR);
    else
      this.paintTable(table_id, NORMAL_COLOR);
  }

  // function that paints the given table in given color
  paintTable(table_id: number, color: THREE.Color) {
    (<any> this.tables[table_id].mesh.material).color = color;
  }

  isVIP(table_id: number) {
    return this.tables[table_id] && this.tables[table_id].type === 1
  }

  // function to call on component unmount (closing tab)
  unmountSocket() {
    // disconnect socket
    if(this.socket !== undefined)
      this.socket.close();

    this.socket = undefined;

    // remove sessionKey from storage
    this.sessionKey = undefined;
    localStorage.removeItem('session_key');
  }

  returnCameraToInitialPosition() {
    // terminate all tweens except this one
    this.terminateTweensExceptOne('initial');
    this.tweenInitial.runned = true;
    // return currently painted section to normal color
    if(this.currentSection !== 0) {
      // blink tables in selected section
      for(let table of Object.values(this.tables))
        if(table.section_id === this.sections[Object.keys(this.sections)[this.currentSection - 1]].id)
          this.paintTable(table.id, NORMAL_COLOR);
    }
    // return current table to normal color
    if(this.availableTables[this.currentTable - 1])
      this.paintByType(parseInt(this.availableTables[this.currentTable - 1]));
    // set currently selected section to 0
    this.currentSection = 0;
    // remove selected table
    this.currentTable = 1;
    // change current table number on controller
    this.updateTableState(-1, 0, this.availableTables.length, { sectionID: -1, sectionName: "" });
    // protection
    if(!this.cameraSetup) return;
    // calculate new camera position
    let newCameraPosition = this.getPositionObject(this.cameraSetup.position || [0,0,0]);
    // set new lookAt position
    let newCameraLookAt = this.getPositionObject(this.cameraSetup.lookAt || [0,0,0]);
    // get current lookAt position
    let currentCameraLookAt = this.lastPosition;
    // this as self
    let self = this;
    // Tween animation for camera position
    this.tweenInitial.t1 = new TWEEN.Tween(this.camera.position).to(newCameraPosition, 1100).easing(TWEEN.Easing.Quadratic.InOut).onUpdate(function () {
      // update camera position on each step
    }).start();
    // Tween animation for camera zoom
    let zoom = { zoom: this.camera.zoom }
    if(this.camera.zoom !== 1)
      this.tweenInitial.t3 = new TWEEN.Tween(zoom).to({ zoom: 1 }, 1100).easing(TWEEN.Easing.Quadratic.InOut).onUpdate(function () {
        // update camera position on each step
        self.camera.zoom = zoom.zoom;
        self.camera.updateProjectionMatrix();
      }).start();
    // Tween animation for lookAt position
    this.tweenInitial.t2 = new TWEEN.Tween(currentCameraLookAt).to(newCameraLookAt, 1100).easing(TWEEN.Easing.Quadratic.InOut).onUpdate(function () {
      // update camera lookAt Vector
      self.camera.lookAt(new THREE.Vector3(currentCameraLookAt.x, currentCameraLookAt.y, currentCameraLookAt.z));
    }).onComplete(() => {
      // set badge as false when animation is completed (stop re-rendering)
      this.tweenInitial.runned = false; 
    }).start();
    // update controls target
    this.controls.target.set( newCameraLookAt.x, newCameraLookAt.y, newCameraLookAt.z );
    // start animation (re-rendering proccess)
    requestAnimationFrame(this.animateCamera.bind(this));
    // set current lookAt position as last position
    this.lastPosition = newCameraLookAt;
    // I'm closing socket
    this.disconnectSocketManually = true;
    // if socket is already opened, close it
    if(this.event_id) {
      // close socket
      this.socket!.close();
      // load socket listeners again
      this.listenersLoaded = false;
    }
  }
}

export default CanvasActions;