import API from '../../helpers/api';

export const eventsAPI = {
  get
}

// function for getting all use places
async function get(params: unknown, { dispatch, rejectWithValue }:any) {
  try {
    // get user places request
    let res = await API.get(`/events/${params}`);
    // return places
    return res.data.events;
  } catch {
    return rejectWithValue();
  }
}

