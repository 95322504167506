import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { removeMessage, MessageScheme } from '../../features/messages/messagesSlice';
import { ExitIcon, SuccessIcon, ErrorIcon } from '../icons';

const MessageContainer = () => {

  const { messages } = useAppSelector(state => state.messages);
  
  if(!Object.keys(messages).length)
    return <></>

  return (
    <div className="message-container">
      {
        Object.keys(messages) && Object.keys(messages).map(id => <Message key={id} {...messages[id]} id={id} />)
      }
    </div>
  )
}

const Message = (props: MessageScheme & { id: string }) => {
  let dispatch = useAppDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(removeMessage(props.id));
    }, 3000);
  }, [])

  return (
    <div className={'message ' + props.type}>
      <div className='message__data'>
        <MessageIcon type={props.type} />
        <div className='message__text'>{ props.content }</div>
      </div>
      <div className='message__remove' onClick={() => dispatch(removeMessage(props.id))}>
        <ExitIcon />
      </div>
    </div>
  )
}

const MessageIcon = (props: { type: string }) => {
  if(props.type === "success")
    return <SuccessIcon />
  else if(props.type === "error")
    return <ErrorIcon />
  // default
  return <></>
}

export default MessageContainer;