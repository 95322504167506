import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRoutes, Navigate } from "react-router-dom";
import { useAppDispatch } from "./app/hooks";
import EventsList from "./components/event/list";
import PrivacyPolicy from "./components/legal-pages/privacy-policy";
import TermsOfUse from "./components/legal-pages/terms-of-use";
import Loading from "./components/loading";
import RequestForTable from "./components/request";
import Layout from "./containers/layout";
import ClubSuspended from "./containers/suspended";
import { getCurrencies } from "./features/currency-list/currencySlice";
import { getEvents } from "./features/events/eventsSlice";
import { getPlace } from "./features/places/placesSlice";
import { getMaintainceStatus } from "./helpers";
import MaintainceMode from "./containers/maintaince";
import CookiePolicy from "./components/legal-pages/cookie-policy";

// App routes
const App = () => {
  const dispatch = useAppDispatch();
  const {i18n, ready} = useTranslation();
  const [suspendState, setSuspended] = useState({status: false, place_name: ''});
  const [status, setStatus] = useState({maintaince: false, duration: ''});


  useEffect(() => {
    (async () => {
      let status = await getMaintainceStatus();
      setStatus(status);
      // if page is not in maintaince
      if(!status.maintaince) {
        // remove www from location host if needed
        let host = window.location.host;
        if(host.indexOf('www.') === 0)
          host = host.replace('www.','');
        let res = await dispatch(getPlace(host));
        // do action after dispatch
        if(res.payload) {
          if(res.payload.suspended)
            setSuspended({status: true, place_name: res.payload.name});
          else {
            // get events
            await dispatch(getEvents(res.payload.slug));
            // get currency list
            await dispatch(getCurrencies(true));
            // set document title
            document.title = res.payload.name + " | Book a table";
          }
        } else {
          setSuspended({status: true, place_name: 'NaN'});
        }
      }
    })();
  }, []);

  // load languages
  useEffect(() => {
    if(ready && i18n.language)
      // assign lang attribute
      document.documentElement.lang = i18n.language;
  }, [ready]);

  let element = useRoutes([
    { 
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "",
          element: <EventsList />
        },
        {
          path: "request",
          element: <RequestForTable />
        },
        {
          path: "privacy-policy",
          element: <PrivacyPolicy />
        },
        {
          path: "terms-of-use",
          element: <TermsOfUse />
        },
        {
          path: "cookie-policy",
          element: <CookiePolicy />
        }
      ]
    },
    {
      path: "*",
      element: <Navigate to="/" replace />
    }
  ]);

  // languages didn't loaded return loading
  if(!ready) return <Loading />

  if(status.maintaince) return <MaintainceMode {...status} />;

  if(suspendState.status) return <ClubSuspended place_name={suspendState.place_name}/>
  
  return element;
};


export default App;