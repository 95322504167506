const Loading = (props: { small?: boolean, fullpage?: boolean }) => {
  if(props.fullpage)
    return <div className="loader-wrapper--fullscreen"><div className="loader"></div></div>

  if(props.small)
    return <div className="loader small"></div>;

  return <div className="loader-wrapper"><div className="loader"></div></div>
}

export default Loading;