import { useState } from "react"

interface ImageProps {
  src: string,
  alt?: string,
  isSvg?: boolean,
  fadeIn?: boolean
}

export const Image = (props: ImageProps) => {
  const [loaded, setLoaded] = useState(false);

  const onLoad = () => {
    setLoaded(true);
  }

  if(props.isSvg)
    return <img className={(loaded ? "loaded" : "") + (props.fadeIn ? " fadeIn" : "")} src={props.src} alt={props.alt} loading='lazy' onLoad={onLoad}/>

  return (
    <picture className={(loaded ? " loaded" : "")}>
      <img className={(props.fadeIn ? "fadeIn" : "")} src={props.src} alt={props.alt} loading='lazy' onLoad={onLoad}/>
    </picture>
  )
}