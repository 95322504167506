import axios from "axios";
import config from "../../app/config";

export const currencyAPI = {
  get
}

// function for getting all use events
async function get(params: unknown, { dispatch, rejectWithValue }:any) {
  try {
    // get user places request
    let res = await axios.get(`${config.assets}currency.json`);
    // return places
    return res.data;
  } catch (err) {
    return rejectWithValue();
  }
}