import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import eventsSlice from '../features/events/eventsSlice';
import messagesSlice from '../features/messages/messagesSlice';
import placesSlice from '../features/places/placesSlice';
import currencySlice from '../features/currency-list/currencySlice';

export const store = configureStore({
  reducer: {
    place: placesSlice,
    events: eventsSlice,
    messages: messagesSlice,
    currencies: currencySlice
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
