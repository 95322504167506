import config from "../app/config";
import API from '../helpers/api';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader'
import { BufferGeometry } from 'three';
import axios from "axios";

const weekDays = ["sunday", "monday", "thuesday", "wednesday", "thursday", "friday", "saturday"];

// format date function
export const formatDate = (date: Date, type: number, t?: any) => {
  if(type === 1)
    return ("0" + date.getDate()).slice(-2) + "." + ("0"+(date.getMonth()+1)).slice(-2) + "." + date.getFullYear() + ", " + t!(weekDays[date.getDay()], {ns: 'general'})
  else if(type === 2)
    return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
  else if(type === 3)
    return ((date.getHours() > 9) ? date.getHours() : '0' + date.getHours()) + ':' + ((date.getMinutes() > 9) ? date.getMinutes() : '0'+date.getMinutes());
  else return '';
}

// function to check if days have passed
export const DaysPast = (date:Date, num_days: number) : boolean => {
  return new Date(date.getTime() + 1000*60*60*24*num_days) > new Date();
}
// function to move time
export const ReverseDate = (date:Date, num_hours: number) : Date => {
  return new Date(date.getTime() + 1000*60*60*num_hours);
}

// format date function
export const endWeekDate = () => {
  let date = new Date();
  var lastday = date.getDate() - (date.getDay() - 1) + 7;
  return new Date(new Date(date.setDate(lastday)).setHours(0,0,0,0))
}


// function to format array to object
export function _format(array:any, lead:string) {
  var formated:any = {};
  // loop through elements
  for(let item of array) 
    formated[item[lead]] = item;
  // return formated object
  return formated;
}

// function that returns request data directly (simple loading from API)
export async function _load(slug: string) {
  let req = await API.get(config.api+slug);
  return req.data;
}

// STL loader part (custom async)
const loader = new STLLoader();
// function to load object geometry (async)
export const loadObject = (link: string) => {
  return new Promise<BufferGeometry>((resolve) => {
    // call loader
    loader.load(link, (geometry: BufferGeometry) => {
      // resolve promise
      resolve(geometry);
    });
  })
}

// get maintaince status
export const getMaintainceStatus = async () => {
  try {
    // get user places request
    let res = await axios.get(`${config.assets}status.json?t=${(new Date()).getTime()}`);
    // return places
    return res.data;
  } catch {
    return {};
  }
}

// function to set cookie
export function setCookie(name: string, value: string, days: number) {
  // protection
  if(!name || name === "" || days === 0 || !days) return;
  // calculate expire date
  let date = new Date();
  date.setTime(date.getTime() + (days*24*60*60*1000));
  // store cookie
  document.cookie = `${name}=${value || ""}; expires=${date.toUTCString()}; path=/`;
}

// function to get cookie
export function getCookie(name: string) {
  // protection
  if(!name || name === "") return;
  // name and equal sign
  var nameEQ = name + "=";
  // split cookies to array
  var ca = document.cookie.split(';');
  // loop through cookies array
  for(let cookie of ca) {
    // remove empty space at the begining of cookie
    while(cookie.charAt(0) === ' ')
      cookie = cookie.substring(1, cookie.length);
    // if cookie found, return cookie
    if(cookie.indexOf(nameEQ) === 0) 
      return cookie.substring(nameEQ.length, cookie.length);
  }
  // cookie not found, so return null
  return null;
}

// function to erase cookie
export function eraseCookie(name: string) {   
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}