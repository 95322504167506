import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Loading from "../loading";
import CanvasActions from './actions';
import EventBadge from "./event-badge";
import Form from "../form";
import Scanner from "./scanner";
import { newMessage } from "../../features/messages/messagesSlice";
import { MessageScheme } from "./enums";
import { selectEvent, setSelectedSection } from "../../features/events/eventsSlice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BottleIcon } from "../icons";

interface CanvasScheme {
  mode?: number,
  event_id?: number,
  isOpened?: boolean
}

// actions variable
let actions: CanvasActions | undefined = undefined;

const Canvas = (props: CanvasScheme) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // canvas reference
  const canvasRef = useRef<HTMLCanvasElement>(null);
  // get events and places from redux store
  const { selected_event, selected_section_name } = useAppSelector( state => state.events );
  const { place } = useAppSelector( state => state.place );
  const [isLoading, setLoading] = useState(true);
  const [availableTablesNumber, setAvailableTablesNumber] = useState(0);
  const [currentTableNumber, setCurrentTableNumber] = useState(0);
  const [selectedTable, setSelectedTable] = useState(-1);
  const [scannerState, setScannerState] = useState(false);
  const [userData, updateUserData] = useState({ name: "", phone: "" });
  const [reservationStatus, setReservationStatus] = useState("unknown");
  const [reservationLink, setReservationLink] = useState("");
  const [isInReservationProccess, setReservationProccess] = useState(false);
  const [shouldClearInterval, setShouldClearInterval] = useState(false);
  // translation
  const {t} = useTranslation(['general', 'errors']);
  useEffect(() => {
    actions = new CanvasActions(canvasRef.current!, place);
    // set callbacks
    actions.onLoaded = onLoaded;
    actions.loadingSocket = loadingSocket;
    actions.updateTableState = updateTableState;
    actions.onError = onError;
    actions.openScanner = openScanner;
    actions.sendMessage = sendMessage;
    actions.reservationsStatus = onReservationsStatus;
    actions.noTables = noTables;
    actions.noSections = noSections;
    // add function on unload (clear local storage)
    function handleUnload() {
      // remove session key when page is reloaded (session key stays when you enter another app and come back)
      localStorage.removeItem('session_key');
    }
    window.addEventListener("unload", handleUnload);
    // on component unmount
    return () => {
      window.removeEventListener("unload", handleUnload);
      setSelectedTable(-1);
      actions && actions.unmountSocket();
    }
  }, []);

  useEffect(() => {
    // wait canvas to load if not loaded
    if(isLoading) return;
    // set event on select or if selected before then loaded, on isLoading status change set event
    if('id' in selected_event) {
      if(selected_event.event_mode === 'choose_table' && place.type === 0)
        actions && (actions.setEvent(selected_event.id, selected_event))
      else if(selected_event.event_mode === 'choose_section' && place.type === 0)
        actions && (actions.chooseSection(selected_event.id, selected_event))
    } else {
      setReservationStatus("unknown");
      // return camera to initial position (triggired when you go back to events list)
      actions && actions.returnCameraToInitialPosition();
    }
  }, [selected_event, isLoading])

  // set state as not loading anymore (remove loader)
  const onLoaded = () => {
    setLoading(false);
  }
  
  // set state as loading
  const loadingSocket = () => {
    setLoading(true);
  }

  // trigger function when there is no available tables
  const noTables = () =>  {
    // dispatch message
    dispatch(newMessage({ type: "error", content: t("all_tables_reserved_text") }));
    // go back to event select
    dispatch(selectEvent({}));
  }
  
  // trigger function when there is no available sections
  const noSections = () =>  {
    // dispatch message
    dispatch(newMessage({ type: "error", content: t("all_sections_reserved_text", { ns: 'errors' }) }));
    // go back to event select
    dispatch(setSelectedSection({ sectionID: -1, sectionName: "" }));
  }

  // function to dispatch messages from canvas actions
  const sendMessage = (data: MessageScheme) => {
    if(!data.msg || !data.type) return;
    dispatch(newMessage({ type: data.type, content: t(data.msg, {ns: 'socket'}) }));
  }

  const onError = (data: MessageScheme) => {
    if(!data.error) return;
    dispatch(newMessage({ type: "error", content: t(data.error, {ns: 'socket'}) }));
    // set reservation proccess status
    setReservationProccess(false);
    // open scanner if sms code wrong
    if(data.type === "sms_error")
      setScannerState(true);
  }

  const onReservationsStatus = (status: string, link?: string) => {
    setReservationStatus(status);
    // set link if exist
    if(link)
      setReservationLink(link)
    // clear interval
    if(status === "failed") {
      setShouldClearInterval(true)
      // close scanner
      setScannerState(false);
    }
  }

  const updateTableState = (tableID: number, currentTableIndex: number, availableTablesNumber: number, section?: { sectionID: number, sectionName: string }) => {
    setAvailableTablesNumber(availableTablesNumber);
    setCurrentTableNumber(currentTableIndex);
    setSelectedTable(tableID);
    if(section) {
      dispatch(setSelectedSection(section))
    }
  }

  const onScan = (sms_code: string) => {
    if(!actions) return;
    // check sms code
    actions.onSMSCode(sms_code);
    // close scanner
    setScannerState(false);
    // set reservation status as loading
    setReservationStatus("loading");
  }

  const handleReservation = (userData: { name: string, phone: string }) => {
    if(!actions || selectedTable === -1) return;
    // make a new reservation request
    actions.newReservation({...userData, selected_table: selectedTable});
    // update user data so we can show phone number on scanner
    updateUserData(userData);
    // set reservation proccess status
    setReservationProccess(true);
  }

  const openScanner = () => {
    // open scanner
    setScannerState(true);
  }

  // function to close scanner and abort reservation
  const closeScanner = () => {
    // abort reservation proccess
    actions && actions.abort();
    // close scanner
    setScannerState(false);
  }

  const selectSection = () => {
    navigate("/request");
  }

  const minBottlesBadge = () => {
    if(selectedTable === -1)
      return <></>
    
    if(actions?.isVIP(selectedTable) && parseInt(selected_event.minimum_bottles_vip) > 0)
      return <div className="badge vip small">
        <BottleIcon />
        { selected_event.minimum_bottles_vip }
      </div>
    
    if(!actions?.isVIP(selectedTable) && parseInt(selected_event.minimum_bottles_classic) > 0)
      return <div className="badge small">
        <BottleIcon />
        { selected_event.minimum_bottles_classic }
      </div>
    
    return <></>
  }

  return (
    <>
      <div className="canvas__wrapper">
        { 'id' in selected_event && 
            <div className="canvas__header container">
              <div className="canvas__badges">
                <EventBadge event={selected_event} club_slug={place.slug} />
                <div className="canvas__badges-list">
                  {
                    selected_section_name !== "" &&
                      <div className="badge vip">{ selected_section_name }</div>
                  }
                  {
                    selectedTable !== -1 && actions?.isVIP(selectedTable) &&
                      <div className="badge vip">{t("vip_table")}</div>
                  }
                </div>
              </div>
            </div>
        }
        { isLoading && <Loading /> }
        <Form onReserve={handleReservation} 
          onAction={(direction: string) => actions && (selected_event.event_mode === 'choose_table' ? actions.handleControllerAction(direction) : actions?.handleSectionControllerAction(direction))} 
          availableTables={availableTablesNumber} 
          currentTable={currentTableNumber} 
          reservationsStatus={reservationStatus} 
          isInReservationProccess={isInReservationProccess} 
          selectSection={selectSection}
          reservationLink={reservationLink}
          minBottlesBadge={minBottlesBadge}
          />
        <canvas ref={canvasRef}></canvas>
      </div>
      <Scanner phoneNumber={userData.phone} opened={scannerState} closeScanner={closeScanner} onScan={onScan} shouldClearInterval={shouldClearInterval} />
    </>
  )
}

export default Canvas;