import EventsScheme from '../../features/events/eventsEnum';
import Event from './index';

interface EventsSectionProps {
  events: EventsScheme[],
  slug: string,
  title: string,
  bigger?: boolean,
  onEventClick: (id: number) => void
}

const EventsSection = (props: EventsSectionProps) => {

  if(props.events.length === 0)
    return <></>

  return (
    <div className="events-section">
      <h2>{ props.title }</h2>
      <div className="grid">
        {/* <!-- Start of - Event single --> */}
        { 
          props.events.map((event, i) => 
            <Event 
              key={event.id}
              bigger={
                props.bigger ?
                  props.events.length % 2 ? (i === 0 ? true : false) : (i < 2 ? true : false)
                : false
              }
              {...event}
              slug={props.slug}
              onClick={() => props.onEventClick(event.id)}
            />
          ) 
        }
        {/* <!-- End of - Event single --> */}
      </div>
    </div>
  )
};

export default EventsSection;