export function shuffle(t:any[]) { 
  let last = t.length
  let n;

  while (last > 0) {
    n = rand(last)
    swap(t, n, --last)
  }
}

const rand = (n:any) =>
  Math.floor(Math.random() * n)

function swap(t:any, i:any, j:any) { 
  let q = t[i]
  t[i] = t[j]
  t[j] = q
  return t
}