import { useNavigate } from 'react-router-dom';
import { ReactComponent as BackArrow } from '../../assets/icons/back-arrow.svg'; 

interface BackLinkProps {
  text: string,
  hideTextOnMob?: boolean,
  onClick?: () => void
}

export const BackLink = (props: BackLinkProps) => {

  let navigate = useNavigate();

  return (
    <div className="back-link" onClick={() => props.onClick ? props.onClick() : navigate(-1)}>
      <BackArrow />
      { 
        !(props.hideTextOnMob && (window.innerWidth < 800)) && <div className="label--gray">{props.text}</div>
      }
    </div>
  )
}