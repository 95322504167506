import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import Canvas from "../../components/canvas";
import Header from "../../components/header";
import Loading from "../../components/loading";
import MessageContainer from "../../components/messages";
import CookieConsent from "../../components/cookie-consent";

const Layout = () => {
  const { place: { place }, currencies: {currencies} } = useAppSelector(state => state);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if('slug' in place && Object.keys(currencies).length !== 0) 
      setLoaded(true);
  }, [place, currencies]);

  if(!loaded)
    return <Loading fullpage={true} />

  return (
    <>
      <Header />
      <Outlet />
      <MessageContainer />
      <CookieConsent />
      {
        place.type === 0 && <Canvas />
      }
    </>
  )
}

export default Layout;