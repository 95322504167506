import { useEffect, useState } from "react";
import { getCookie, setCookie } from "../../helpers";
import { useTranslation } from "react-i18next";


const CookieConsent = () => {
  let [showConsent, setShowConsent] = useState(false);

  const { t } = useTranslation(['buttons', 'general']);

  useEffect(() => {
    // check if cookies are enables
    if(!navigator.cookieEnabled)
      alert(t("enable_cookies", { ns: 'general' }))
    // try to get cookies allowed cookie
    let areCookiesAllowed = getCookie('_ca');
    // set to state
    setShowConsent(!areCookiesAllowed);
  }, [])

  let allowCookies = () => {
    // set allowed cookies as true
    setCookie('_ca', 'true', 30);
    // remove consent
    setShowConsent(false);
  }

  // if cookies are allowed already, don't show consent
  if(!showConsent)
    return <></>;

  // show consent (if cookies are not allowed yet)
  return (
    <div className="cookie-consent__wrapper">
      <h3 className="cookie-consent__title">{t('cookie_consent_title', { ns: 'general' })}</h3>
      <p className="cookie-consent__text" dangerouslySetInnerHTML={{ __html: t('cookie_consent_text', { ns: 'general' }) }}></p>
      <div className="button" onClick={allowCookies}>{t('accept_cookies', { ns: 'buttons' })}</div>
    </div>
  )
}

export default CookieConsent;