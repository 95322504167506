import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import config from "../../app/config";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectEvent } from "../../features/events/eventsSlice";
import { BackArrow, FacebookIcon, InstagramIcon } from "../icons";
import LanguageSwitcher from "../language";


const Header = () => {
  const dispatch = useAppDispatch();
  const { place } = useAppSelector(state => state.place);
  const { selected_event } = useAppSelector(state => state.events);
  // translation
  const {t} = useTranslation(['buttons', 'general']);
  
  const [navbarOpen, setNavbarOpen] = useState(false);

  // function to toggle mobile navbar
  const toggleNavbar = () => setNavbarOpen(!navbarOpen);
  // function to close mobile navbar
  const closeNavbar = () => setNavbarOpen(false);

  let returnToEvents = () => {
    dispatch(selectEvent({}));
  }

  if(('id' in selected_event && place.type === 0 && selected_event.event_mode !== 'request'))
    return <></>

  return (
    <>
      <header className={"container" + (('id' in selected_event && selected_event.event_mode !== 'request') ? "" : " blured") + (navbarOpen ? " opened" : "")}>
        {
          ('id' in selected_event && place.type === 0 && selected_event.event_mode !== 'request') ?
            <div className="exit-event" onClick={returnToEvents}><BackArrow /><div className="label--gray">{t("back2", {ns: 'buttons'})}</div></div>
          :
            <div className="hamburger" onClick={toggleNavbar}></div>
        }
        <NavLink to="/" onClick={closeNavbar}>
          <div className="logo">{(place.logo) ? <img src={config.assets + place.slug + place.logo}/> : place.name}</div>
        </NavLink>
        <LanguageSwitcher />
      </header>
      <div className={"sidebar__menu" + (navbarOpen ? " opened" : "")}>
        <div className="container">
          <div className="menu-box">
            <h4 className="label--gray">{t("address_text", {ns: 'general'})}</h4>
            <div className="sidebar__link">{ place.address }</div>
          </div>
          <div className="menu-box">
            <h4 className="label--gray">{t("contact_number_text", {ns: 'general'})}</h4>
            <div className="sidebar__link"><a href={"tel:" + place.contact_number}>{place.contact_number}</a></div>
          </div>
          {
            place.email &&
              <div className="menu-box">
                <h4 className="label--gray">{t("email_text", {ns: 'general'})}</h4>
                <div className="sidebar__link"><a href={"mailto:" + place.email}>{place.email}</a></div>
              </div>
          }
          <div className="menu-box">
            <h4 className="label--gray">{t("social_media_text", {ns: 'general'})}</h4>
            <div className="socials">
              <a href={place.instagram} target="_blank"><InstagramIcon /></a>
              <a href={place.facebook} target="_blank"><FacebookIcon /></a>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="sidebar__links">
            <div className="sidebar__link">
              <NavLink to="/privacy-policy" onClick={closeNavbar}>{t("privacy_policy", {ns: 'general'})}</NavLink>
            </div>
            <div className="sidebar__link">
              <NavLink to="/terms-of-use" onClick={closeNavbar}>{t("terms_of_use", {ns: 'general'})}</NavLink>
            </div>
            <div className="sidebar__link">
              <NavLink to="/cookie-policy" onClick={closeNavbar}>{t("cookie_policy", {ns: 'general'})}</NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default Header;