import { useTranslation } from "react-i18next";
import config from "../../app/config";
import EventsScheme from "../../features/events/eventsEnum";
import { formatDate } from "../../helpers";
import { Image } from "../image";
import { useAppDispatch } from "../../app/hooks";
import { selectEvent } from "../../features/events/eventsSlice";
import { BackArrow } from "../icons";

interface EventBadgeSceheme {
  event: EventsScheme,
  club_slug?: string,
  isInRequest?: boolean
}

const EventBadge = (props: EventBadgeSceheme) => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation(['general']);

  let returnToEvents = () => dispatch(selectEvent({}));

  if(!('id' in props.event))
    return <></>

  return (
    <div className={"event-reminder" + (props.isInRequest ? " in-request" : "")}>
      <div className="exit-event" onClick={returnToEvents}><BackArrow /><div className="label--gray">{t("back2", {ns: 'buttons'})}</div></div>
      <div className="event-reminder__banner">
        <Image src={`${config.assets}${props.club_slug}${props.event.banner}`} alt="" />
      </div>
      <div>
        <h4 className="event-reminder__h4">{ props.event.name }</h4>
        <h6 className="event-reminder__label label--gray">{ (new Date(props.event.event_start) > new Date()) ? formatDate(new Date(props.event.event_start), 1, t) : t("event_going_text") }</h6>
      </div>
    </div>
  );
}

export default EventBadge;