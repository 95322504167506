import { useTranslation } from "react-i18next";
import Footer from "../footer";
import { BackLink } from "../link";

const TermsOfUse = () => {
  const {t} = useTranslation(['buttons', 'general', 'legal']);

  return (
    <div className={"content container"}>
      <div className="grid maxw">
        <div className="legal-content">
          <BackLink text={t("back2", {ns: 'buttons'})} />
          <h1 className="legal-content__h1">{t("terms_of_use", {ns: 'general'})}</h1>
          <div dangerouslySetInnerHTML={{ __html: t("terms_of_use_text", {ns: 'legal'}) }}></div>
        </div>
        <Footer />
      </div>
    </div>
  )
};

export default TermsOfUse;