import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectEvent } from "../../features/events/eventsSlice";
import EventBadge from "../canvas/event-badge";
import Footer from "../footer";
import { Counter } from "../form/counter";
import TelInput from "../form/phoneInput";
import { BackLink } from "../link";
import { io, Socket } from "socket.io-client";
import { ClientToServerEvents, MessageScheme, ServerToClientEvents } from "../canvas/enums";
import config from "../../app/config";
import { newMessage } from "../../features/messages/messagesSlice";
import Scanner from "../canvas/scanner";
import { isPhoneSubscribed, subscribePhone } from "../../helpers/api/newsletter";
import { ButtonArrow, LogoutIcon, PhoneMessageIcon, ReservationErrorIcon, ReservationSuccessIcon } from "../icons";
import Loading from "../loading";
import AgePrompt from "../prompt";

const RequestForTable = () => {
  // translation and navigate
  const dispatch = useAppDispatch();
  const {t} = useTranslation(['general', 'inputs', 'buttons']);
  const navigate = useNavigate();
  // redux
  const { selected_event, selected_section_name, selected_section } = useAppSelector( state => state.events );
  const { place } = useAppSelector( state => state.place );
  // component states
  const [userData, updateUserData] = useState({ name: "", phone: "", persons: 4, note: "", age_average: 18 });
  const [socket, updateSocket] = useState<Socket<ServerToClientEvents, ClientToServerEvents> | undefined>(undefined);
  const [scannerState, setScannerState] = useState(false);
  const [reservationStatus, setReservationStatus] = useState("unknown");
  const [reservationLink, setReservationLink] = useState("");
  const [sessionKey, setSessionKey] = useState<string | undefined>(undefined);
  const [shouldClearInterval, setShouldClearInterval] = useState(false);
  const [subscribeStatus, setSubscribeStatus] = useState("no");
  const [isPromptOpened, setPromptState] = useState(false);

  useEffect(() => {
    // check if event is set, and is not 3D mode
    if('id' in selected_event && (place.type === 1 || selected_event.event_mode !== 'choose_table')){
      // update min age
      updateUserData((prevState) => ({ ...prevState, age_average: selected_event.min_age }));
      // get session from storage
      let storedSession = localStorage.getItem('session_key');
      // if session was stored
      if(storedSession) {
        // extract stored event id and stored session key
        let [storedEventId, storedSessionKey] = String(storedSession).split('.').slice(0, 2);
        // is the same event selected as the stored one, if is send stored session key on socket auth
        if(parseInt(storedEventId) === selected_event.id)
          setSessionKey(storedSessionKey)
      }
      // connect to socket
      updateSocket(io(config.socket, {
        transports: ["websocket"],
        auth: {
          club: place.slug,
          event_id: selected_event.id,
          session_key: sessionKey
        }
      }))
    } else {
      if(socket)
        socket.close();
      // remove sessionKey from storage
      setSessionKey(undefined);
      localStorage.removeItem('session_key');
      // navigate to all events
      return navigate("/", { replace: true })
    }
  }, [selected_event])

  useEffect(() => {
    // if socket is undefined return
    if(!socket) return;
    // subscribe to events if socket is connected
    socket.on("connect", () => {});
    socket.on("message", data => onMessage(data));
    socket.on("success_sms", data => onSuccessSMS(data));
    socket.on("new_session_key", data => onSessionChange(data));
    socket.on("reservation_success", data => onSuccessReservation(data));
    socket.on("disconnect", () => {});
  }, [socket]);

  // function to update sessionKey
  const onSessionChange = (data: { new_session_key: string }) => {
    // update socket manager
    socket!.auth = { ...socket!.auth, session_key: data.new_session_key };
    // store socket id to variable
    setSessionKey(data.new_session_key)
    // store socket id also to local storage
    localStorage.setItem('session_key', `${selected_event.id}.${data.new_session_key}`);
  }

  // dispatch message from socket
  const onMessage = (data: MessageScheme) => {
    if(!data.error) return;
    // dispatch error message
    dispatch(newMessage({ type: "error", content: t(data.error, {ns: 'socket'}) }));
    // open scanner if sms code wrong
    if(data.type === "sms_error")
      setScannerState(true);
  }

  // called when server sent SMS successfully or it failed
  const onSuccessSMS = (data: { success: boolean }) => {
    // open scanner if SMS was delivered
    if(data.success)
      setScannerState(true);
  }

  // on successful or failed reservation
  const onSuccessReservation = (data: {success: boolean, link: string}) => {
    let status = data.success ? "success" : "failed"
    // update state
    setReservationStatus(status);
    // store reservation link
    if(status === "success")
      setReservationLink(data.link);
    // clear interval
    if(status === "failed") {
      setShouldClearInterval(true)
      // close scanner
      setScannerState(false);
      // remove reservation link if set
      setReservationLink("");
    }
    // after reservation process ends, remove sessionKey from storage
    setSessionKey(undefined);
    localStorage.removeItem('session_key');
  }

  // function to handle any input (name and surname in this case)
  const handleInput = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let name = e.currentTarget.name;
    let value = e.currentTarget.value;
    updateUserData((prevState) => ({...prevState, [name]: value}))
  }

  // function to handle phone input
  const handlePhoneInput = (phone: string) => {
    if(phone !== userData.phone)
      updateUserData((prevState) => ({...prevState, phone}));
  }

  // function for persons handler +/-
  const personsHandler = (value: number)=> {
    updateUserData((prevState) => ({...prevState, persons: prevState.persons + value}));
  }

  const ageAverageHandler = (value: number) => {
    updateUserData((prevState) => ({...prevState, age_average: prevState.age_average + value}));
  }

  // return to events
  let returnToEvents = () => {
    if(socket)
      socket.close();
    // remove sessionKey from storage
    setSessionKey(undefined);
    localStorage.removeItem('session_key');
    // navigate to all events
    navigate("/", { replace: true })
    // unselect event
    dispatch(selectEvent({}));
  }

  // function called when user enteres 4 digits from SMS code
  const onScan = (sms_code: string) => {
    // check sms code
    socket && socket.emit("sms_code", { sms_code: sms_code });
    // close scanner
    setScannerState(false);
    // set reservation status as loading
    setReservationStatus("loading");
  }

  // function to close scanner and abort reservation
  const closeScanner = () => {
    // abort reservation proccess
    socket && socket.emit("abort");
    // close scanner
    setScannerState(false);
  }

  const processReservation = async (action: boolean) => {
    // close prompt
    setPromptState(false);

    // emit new reservation if age is confirmed
    action && socket && socket.emit("new_reservation", { 
      customer_name: userData.name,
      phone_number: userData.phone,
      group_size: userData.persons,
      age_average: userData.age_average,
      note: userData.note,
      section_id: (selected_event.event_mode === 'choose_section' && selected_section !== -1) ? selected_section : undefined
    });
  }

  // function to handle new reservation
  const handleReservation = async () => setPromptState(true);

  const checkShouldSubscribe = async () => {
    try{
      // make API request to check if phone number is already subscribed
      let isSubscribed = await isPhoneSubscribed(userData.phone, place.slug);
      if(isSubscribed) return window.location.reload();
      // set should subscribe flag if phone is not already subscribed
      setSubscribeStatus("newsletter");
    } catch(e) {
      window.location.reload();
    } 
  }

  const subscribe = async () => {
    try {
      // subscribe phone number
      let subscribed = await subscribePhone(userData.phone, place.slug);
      if(!subscribed) window.location.reload();
      // display success message
      setSubscribeStatus("newsletter-done");
    } catch(e) {
      window.location.reload();
    }
  }

  return (
    <>
      <div className="content container">
        <div className="grid maxw">
          <div className="request-a-table">
            <BackLink text={t("back2", {ns: 'buttons'})} onClick={returnToEvents} />
            <h1 className="legal-content__h1">{t("table_reservation", {ns: 'general'})}</h1>
            <EventBadge event={selected_event} club_slug={place.slug} isInRequest />
            {
              selected_event.event_mode !== 'choose_table' && selected_section_name && <div className="picked-section__text">{t("asked_for_section_text", {ns: 'general'})}<span>"{selected_section_name}"</span>. {t("staff_rights_for_section", {ns: 'general'})}</div>
            }
            {
              reservationStatus === "unknown" ?
                <div className="request__inputs">
                  <div className="input-wrapper">
                    <div className="label input__label">{t("name_label", {ns: 'inputs'})}</div>
                    <input type="text" placeholder={t("name_placeholder", {ns: 'inputs'})} value={userData.name} name="name" onChange={handleInput} />
                  </div>
                  <div className="input-wrapper">
                    <div className="label input__label">{t("contact_numbers_label", {ns: 'inputs'})}</div>
                    <TelInput updatePhoneNumber={handlePhoneInput} phone_number={userData.phone}/>
                  </div>
                  <div className="grid--2">
                    <Counter label={t("number_of_persons_label", {ns: 'inputs'})} minValue={1} maxValue={10} value={userData.persons} counterHandler={personsHandler}/>
                    <Counter label={t("age_average_label", {ns: 'inputs'})} minValue={selected_event.min_age} maxValue={65} value={userData.age_average} counterHandler={ageAverageHandler}/>
                  </div>
                  <div className="input-wrapper">
                    <div className="label input__label">{t("additional_note_label", {ns: 'inputs'})}</div>
                    <textarea name="note" placeholder={t("additional_note_placeholder", {ns: 'inputs'})} onChange={handleInput}></textarea>
                  </div>
                  <div onClick={handleReservation} className={"button" + (!userData.name.length || !userData.phone || !userData.phone.length || (userData.age_average < selected_event.min_age) ? " disabled" : "")}>{t("request_a_table", {ns: 'buttons'})}</div>
                  <div className="whatsapp__confirmation">
                    { t("code_via_whatsapp", { ns: "general" }) }
                    <br/><br/>
                    <div dangerouslySetInnerHTML={{ __html: t("accept_pp_tof_by_proceeding", { ns: "general" }) }}></div>
                  </div>
                  <AgePrompt onAction={processReservation} opened={isPromptOpened} age={selected_event.min_age} />
                </div>
              :
                <div className="request__status">
                  <DisplayRequestStatus reservationLink={reservationLink} status={subscribeStatus === "no" ? reservationStatus : subscribeStatus} shouldSubscribe={checkShouldSubscribe} subscribe={subscribe} />
                </div>
            }
          </div>
          <Footer />
        </div>
      </div>
      <Scanner phoneNumber={userData.phone} opened={scannerState} closeScanner={closeScanner} onScan={onScan} shouldClearInterval={shouldClearInterval} />
    </>
  );
}

// Component to display reservation status (success or error)
const DisplayRequestStatus = (props: { status: string, shouldSubscribe: () => void, subscribe: () => void, reservationLink: string }) => {
  // translation
  const {t} = useTranslation(['success', 'errors', 'buttons'])
  if(props.status === "success") {
    return (
      <div className="reservation-status">
        <ReservationSuccessIcon />
        <h2 className="reservation-status__h2">{t("request_a_table_success", {ns: 'success'})}</h2>
        <h6 className="reservation-status__h6">{t("request_a_table_message_success", {ns: 'success'})}</h6>
        <h6 className="reservation-status__h6 reservation-status__link"><a href={props.reservationLink} target="_blank">{t("my_request_link", { ns: 'buttons' })}</a></h6>
        <div className="button no-margin" onClick={() => props.shouldSubscribe()}>{t("next", {ns: 'buttons'})}<ButtonArrow /></div>
      </div>
    )
  } else if(props.status === "failed") {
    return (
      <div className="reservation-status">
        <ReservationErrorIcon />
        <h2 className="reservation-status__h2">{t("booking_unsucess", {ns: 'errors'})}</h2>
        <h6 className="reservation-status__h6">{t("something_wrong", {ns: 'errors'})}</h6>
        <div className="button--back no-margin" onClick={() => window.location.reload()}>{t("back2", {ns: 'buttons'})}<LogoutIcon /></div>
      </div>
    )
  } else if(props.status === "newsletter") {
    return (
      <div className="reservation-status">
        <PhoneMessageIcon />
        <h2 className="reservation-status__h2">{t("newsletter_title", {ns: 'general'})}</h2>
        <h6 className="reservation-status__h6">{t("newsletter_text", {ns: 'general'})}</h6>
        <div className="reservation-status__grid">
          <div className="button--invert no-margin" onClick={() => window.location.reload()}>{t("dont_want", {ns: 'buttons'})}</div>
          <div className="button no-margin" onClick={() => props.subscribe()}>{t("subscribe", {ns: 'buttons'})}</div>
        </div>
      </div>
    )
  } else if(props.status === "newsletter-done") {
    return (
      <div className="reservation-status">
        <ReservationSuccessIcon />
        <h2 className="reservation-status__h2">{t("subscribe_title_success", {ns: 'success'})}</h2>
        <h6 className="reservation-status__h6">{t("newsletter_subscribe_success", {ns: 'success'})}</h6>
        <div className="button--back no-margin" onClick={() => window.location.reload()}>{t("back2", {ns: 'buttons'})}<LogoutIcon /></div>
      </div>
    )
  } else if(props.status === "loading") {
    return <div className="reservation-status">
      <Loading />
    </div>
  }
  // default return
  return <></>
}

export default RequestForTable;