import { FormEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BackLink } from "../link";

interface ScannerScheme {
  opened?: boolean,
  closeScanner?: () => void,
  onScan?: (shortcode: string) => void,
  phoneNumber?: string,
  shouldClearInterval?: boolean
}

var intervalId: any = undefined;

const Scanner = (props: ScannerScheme) => {
  const input1Ref = useRef<HTMLInputElement>(null);
  const input2Ref = useRef<HTMLInputElement>(null);
  const input3Ref = useRef<HTMLInputElement>(null);
  const input4Ref = useRef<HTMLInputElement>(null);
  const inputRefs = [input1Ref, input2Ref, input3Ref, input4Ref];
  const [shortcode, setShortcode] = useState({"1": "", "2": "", "3": "", "4": ""});
  const [notReceived, setNotReceived] = useState(false);
  const [timeLeft, setTimeLeft] = useState(120);

  // translation
  const {t} = useTranslation(['general', 'success', 'buttons'])

  useEffect(() => {
    if(timeLeft <= 0) clearInterval(intervalId);
  }, [timeLeft])

  useEffect(() => {
    // stop countdown
    if(props.shouldClearInterval)
      clearInterval(intervalId);
  }, [props.shouldClearInterval]);

  useEffect(() => {
    // on open focus input, on close set shortcode to initial state
    if(props.opened) {
      input1Ref.current?.focus();
      // start countdown
      intervalId = setInterval(() => {
        setTimeLeft((t) => t - 1);
      }, 1000);
    } else {
      setShortcode({"1": "", "2": "", "3": "", "4": ""});
    }
  }, [props.opened]);
  
  useEffect(() => {
    if(shortcode[4] !== "") {
      // remove focus from last input
      input4Ref.current?.blur();
      // do on scan action
      props.onScan && props.onScan(Object.values(shortcode).join(""));
    }
  }, [shortcode]);
  
  const onChange = (e: FormEvent<HTMLInputElement>) => {
    if(!props.opened) return;
    // values
    let digit = parseInt(e.currentTarget.name, 10);
    let value = e.currentTarget.value;
    // on paste from SMS
    if(value.length === 4) {
      setShortcode(() => ({"1": value[0], "2": value[1], "3": value[2], "4": value[3]}));
      return input4Ref.current?.focus();
    }
    // check conditions
    if(value === "" || isNaN(parseInt(value, 10)) || value.length !== 1) return;
    // set changed shortcode digit
    setShortcode((prev) => ({...prev, [digit]: value}));
    // focusIn next input
    if(digit < 4)
      inputRefs[digit].current?.focus();
  }
  
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if(!props.opened || e.key !== 'Backspace') return;
    // digit 1-4
    let digit = parseInt(e.currentTarget.name, 10);
    // set changed shortcode digit
    setShortcode((prev) => ({...prev, [digit]: ""}));
    // focus previous input
    if(digit > 1)
      inputRefs[digit-2].current?.focus();
  }

  const formatCountdown = () => {
    let minutes = Math.floor((timeLeft/60));
    let seconds = timeLeft - 60*minutes;
    return `0${minutes}:${seconds < 10 ? ('0'+seconds) : seconds}`;
  }

  return (
    <div className={"scanner__wrapper" + (props.opened ? " opened" : "")}>
      <BackLink text={t("back2", {ns: 'buttons'})} hideTextOnMob={true} onClick={props.closeScanner} />
      {
        !notReceived ?
          <div className="scanner__content">
            <div className="scanner__title">{t("confirm_booking_title")}</div>
            <div className="scanner__countdown">{ formatCountdown() }</div>
            <div className="scanner__number">{ t("sms_code_success", {ns: 'success', phone: props.phoneNumber && props.phoneNumber.replace(/(\d{3})\d{6}(\d{2})/, '$1******$2') })}</div>
            <div className="scanner__numbers">
              <input type="text" inputMode="numeric" autoComplete="one-time-code" pattern="[0-9]{*}" ref={input1Ref} name="1" value={shortcode[1]} onChange={onChange} onKeyDown={onKeyDown} className="scanner__input" />
              <input type="text" inputMode="numeric" autoComplete="one-time-code" pattern="[0-9]{*}" ref={input2Ref} name="2" value={shortcode[2]} onChange={onChange} onKeyDown={onKeyDown} className="scanner__input" />
              <input type="text" inputMode="numeric" autoComplete="one-time-code" pattern="[0-9]{*}" ref={input3Ref} name="3" value={shortcode[3]} onChange={onChange} onKeyDown={onKeyDown} className="scanner__input" />
              <input type="text" inputMode="numeric" autoComplete="one-time-code" pattern="[0-9]{*}" ref={input4Ref} name="4" value={shortcode[4]} onChange={onChange} onKeyDown={onKeyDown} className="scanner__input" />
            </div>
            <div className="scanner__code-not-received" onClick={() => setNotReceived(true)}>{t("code_not_received", { ns: 'general' })}</div>
          </div>
        :
          <div className="scanner__content">
            <div className="scanner__whatsapp-explanation__title">{t("code_not_received_title", { ns: 'general' })}</div>
            <div className="scanner__whatsapp-explanation__section">1. {t("code_not_received_section_1_title", { ns: 'general' })}</div>
            <div className="scanner__whatsapp-explanation__text" dangerouslySetInnerHTML={{__html: t("code_not_received_section_1_text", { ns: 'general', escapeInterpolation: true })} } />
            <div className="scanner__whatsapp-explanation__section">2. {t("code_not_received_section_2_title", { ns: 'general' })}</div>
            <div className="scanner__whatsapp-explanation__text">{t("code_not_received_section_2_text", { ns: 'general' })}</div>
            <div className="scanner__whatsapp-explanation__again button" onClick={() => window.location.reload()}>{t("try_again", { ns: 'buttons' })}</div>
          </div>
      }
    </div>
  )
}

export default Scanner;