import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../app/hooks';
import EventsScheme from '../../features/events/eventsEnum';
import { endWeekDate } from '../../helpers';
import Footer from '../footer';
import EventPopup from '../popup';
import EventsSection from './section';

function sortEvents(events:Record<number, EventsScheme>):{week: EventsScheme[], other: EventsScheme[]} {
  let sortedEvents = {
    week: new Array(),
    other: new Array()
  }

  for(let id in events) {
    if(new Date(events[id].event_start) < endWeekDate())
      sortedEvents["week"].push(events[id]);
    else 
      sortedEvents["other"].push(events[id]);
  }

  if(sortedEvents.week.length > 0)
    sortedEvents.week.sort((a, b) => {
      let time = (new Date(a.event_start)).getTime() - (new Date(b.event_start)).getTime();
      // if first event start time is equal to second event start time, and first event is cancelled, position second event before first
      if(time === 0 && a.event_cancelled)
        return 1
      // if first event start time is equal to second event start time, but second event is cancelled, leave position as it is
      else if(time === 0 && b.event_cancelled)
        return -1
      else
        return time
    })
  if(sortedEvents.other.length > 0)
    sortedEvents.other.sort((a, b) => ((new Date(a.event_start)).getTime() - (new Date(b.event_start)).getTime()))

  return sortedEvents;
}

const EventsList = () => {
  const {t} = useTranslation(['buttons', 'general']);

  const { place } = useAppSelector(state => state.place);
  const { events, selected_event } = useAppSelector(state => state.events);
  const [isPopupOpened, setPopupState] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(-1);
  const [eventsDisplay, setEventsDisplay] = useState({
    "week": [] as EventsScheme[],
    "other": [] as EventsScheme[]
  });

  useEffect(() => {
    if(Object.keys(events).length !== 0)
      setEventsDisplay(sortEvents(events));
  }, [events])

  useEffect(() => {
    setPopupState(false);
  }, [selected_event]);

  let onEventClick = (id: number) => {
    // set selected id
    setSelectedEvent(id);
    // open popup
    setPopupState(true);
  }

  return (
    <>
      <div className={"content container" + (('id' in selected_event) ? " closed" : "")}>
        <div className="grid maxw">
          <div className="events">
            <h1>{t("events", {ns: 'general'})}</h1>
            {
              !eventsDisplay.week.length && !eventsDisplay.other.length && <p className="no-events__p">{t("no_future_events", {ns: 'general'})}</p>
            }
            <EventsSection title={t("this_week", {ns: 'general'})} events={eventsDisplay.week} slug={place.slug} onEventClick={onEventClick} bigger={true} />
            <EventsSection title={t("upcoming_events", {ns: 'general'})} events={eventsDisplay.other} slug={place.slug} onEventClick={onEventClick} />
          </div>
          <Footer />
        </div>
      </div>
      <EventPopup slug={place.slug} event={selectedEvent} total_tables={place.tables_num} isOpened={isPopupOpened} close={() => setPopupState(false)} />
    </>
  )
};

export default EventsList;