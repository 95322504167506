const config = {
  api: 'https://api.taboo-host.com/', // 'https://api.taboo.hr/',
  assets: 'https://assets.taboo-host.com/', // 'https://assets.taboo.hr',
  icons: 'https://assets.taboo.hr/icons/',
  socket: 'https://socket.taboo-host.com/',
  languages: 'https://assets.taboo-host.com/languages/',
  supportedLngs: {"en": "English", "hr": "Hrvatski"}
}
/*
const config = {
  api: 'http://134.209.254.57:8080/', // 'https://api.taboo.hr/',
  assets: 'http://134.209.254.57/assets/', // 'https://assets.taboo.hr',
  icons: 'https://assets.taboo.hr/icons/',
  socket: 'http://134.209.254.57:8880/',
  languages: 'https://assets.taboo-host.com/languages/',
  supportedLngs: {"en": "English", "hr": "Hrvatski"}
}*/

export default config;