import API from '../../helpers/api';

export const placesAPI = {
  get
}

// function for getting all use places
async function get(params: unknown, { dispatch, rejectWithValue }:any) {
  try {
    // get user places request
    let res = await API.get(`/places/data/${params}`);
    // return places
    return res.data;
  } catch {
    return rejectWithValue();
  }
}

