import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { _format } from '../../helpers';
import { eventsAPI } from './eventsAPI';
import EventsScheme from './eventsEnum';

// initial state
const initialState = {
  events: {} as Record<number, EventsScheme>,
  selected_event: {} as EventsScheme,
  selected_section: -1,
  selected_section_name: ""
};

// get place data
export const getEvents = createAsyncThunk('events/get', eventsAPI.get);


// create user slice
const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    selectEvent: (state, action) => {
      state.selected_event = action.payload;
    },
    setSelectedSection: (state, action) => {
      state.selected_section = action.payload.sectionID;
      state.selected_section_name = action.payload.sectionName;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getEvents.fulfilled, (state, { payload }) => {
      state.events = _format(payload, 'id');
    })
  },
})

export const { selectEvent, setSelectedSection } = eventsSlice.actions;

export default eventsSlice.reducer;