import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import config from "../../app/config";
import PlacesScheme from "../../features/places/placesEnum";

const ClubSuspended = (props: { place_name: string }) => {
  // translation
  const {t} = useTranslation(['buttons', 'general']);

  return (
    <div className="club-suspended">
      <svg width="138" height="39" viewBox="0 0 138 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.5 32.4C16.5 32.7 14.7 35.35 12.1 35.35C9.85 35.35 7.4 33.6 7.4 30.35V16H14.95V12.95H7.4V4.65L4.1 5.05V12.95H0.1V16H4.1V31.1C4.1 36.1 7.6 38.4 11.5 38.4C15.7 38.35 18.15 34.95 18.15 34.95L16.5 32.4ZM42.457 13V17.05C40.107 14.3 36.607 12.6 32.707 12.6C25.557 12.6 19.807 18.35 19.807 25.5C19.807 32.6 25.557 38.4 32.707 38.4C36.607 38.4 40.107 36.65 42.457 33.95V38H45.757V13H42.457ZM32.757 35.25C27.407 35.25 23.107 30.95 23.107 25.5C23.107 20.05 27.407 15.75 32.757 15.75C38.157 15.75 42.357 20.05 42.357 25.5C42.357 30.95 38.157 35.25 32.757 35.25ZM66.1406 12.6C62.2406 12.6 58.6906 14.35 56.3406 17.1V0.799999H53.0406V38H56.3406V33.85C58.6906 36.65 62.2406 38.4 66.1406 38.4C73.2906 38.4 79.0406 32.6 79.0406 25.5C79.0406 18.35 73.2906 12.6 66.1406 12.6ZM66.0406 35.25C60.6906 35.25 56.4406 30.95 56.4406 25.5C56.4406 20.05 60.6906 15.75 66.0406 15.75C71.3906 15.75 75.6406 20.05 75.6406 25.5C75.6406 30.95 71.3906 35.25 66.0406 35.25ZM95.5977 38.4C102.698 38.4 108.498 32.6 108.498 25.5C108.498 18.35 102.698 12.6 95.5977 12.6C88.4477 12.6 82.6977 18.35 82.6977 25.5C82.6977 32.6 88.4477 38.4 95.5977 38.4ZM95.6477 35.25C90.2977 35.25 86.0477 30.95 86.0477 25.5C86.0477 20.05 90.2977 15.7 95.6477 15.7C101.048 15.7 105.248 20.05 105.248 25.5C105.248 30.95 101.048 35.25 95.6477 35.25ZM125.09 38.4C132.19 38.4 137.99 32.6 137.99 25.5C137.99 18.35 132.19 12.6 125.09 12.6C117.94 12.6 112.19 18.35 112.19 25.5C112.19 32.6 117.94 38.4 125.09 38.4ZM125.14 35.25C119.79 35.25 115.54 30.95 115.54 25.5C115.54 20.05 119.79 15.7 125.14 15.7C130.54 15.7 134.74 20.05 134.74 25.5C134.74 30.95 130.54 35.25 125.14 35.25Z" fill="white"/>
      </svg>
      <h1 className="club-suspended__h1">{props.place_name}</h1>
      <p className="club-suspended__p">{t("suspended_text", {ns: 'general', club_name: props.place_name})}</p>
      <div className="button club-suspended__button">{t("back2", {ns: 'buttons'})}</div>
    </div>
  )
}

export default ClubSuspended;