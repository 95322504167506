import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import config from "../../app/config";
import { useAppSelector } from "../../app/hooks";
import { Image } from "../image";

const Footer = () => {
  const {t} = useTranslation(['buttons', 'general']);
  const { place } = useAppSelector(state => state.place);

  return (
    <footer>
      <div className="footer__links">
        <div className="footer__link">
          <NavLink to="/privacy-policy">{t("privacy_policy", {ns: 'general'})}</NavLink>
        </div>
        <div className="footer__link">
          <NavLink to="/terms-of-use">{t("terms_of_use", {ns: 'general'})}</NavLink>
        </div>
      </div>
      <NavLink to="/">
        <div className="logo--footer">{(place.logo) ? <Image src={config.assets + place.slug + place.logo} isSvg /> : place.name}</div>
      </NavLink>
      <div className="copyright">Copyright © <a href="https://taboo.hr">taboo.hr</a> All rights reserved.</div>
    </footer>
  )
};

export default Footer;